import { ApplicationAction } from '../actions';
import { CLEAR_LINE, LOAD_LINE_BY_ID, LOAD_LINE_BY_ID_FAIL, LOAD_LINE_BY_ID_SUCCESS } from '../actions/line';
import { LineId } from '../utils/types';

export type CurrentLineState = {
  readonly lineId: null | LineId;
  readonly error: string | null;
  readonly loaded: boolean;
};

const initialState: CurrentLineState = { lineId: null, error: null, loaded: false };

export default (state: CurrentLineState = initialState, action: ApplicationAction): CurrentLineState => {
  switch (action.type) {
    case CLEAR_LINE:
      return { ...initialState, lineId: null };
    case LOAD_LINE_BY_ID:
      return { ...initialState };
    case LOAD_LINE_BY_ID_SUCCESS:
      return { ...initialState, lineId: action.lineId, loaded: true };
    case LOAD_LINE_BY_ID_FAIL:
      return { ...initialState, error: action.payload };

    default:
      return state;
  }
};
