import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { FilterState } from '../reducers/filter';
import { ApplicationState, Station, StationObject } from '../utils/types';

import { filterSelector, stationsSelector } from './stateSelectors';

type StationObjectOrNull = StationObject | null;

export const getStationObjects = createSelector<ApplicationState, Station[], StationObject[]>(
  [stationsSelector],
  (stations) =>
    stations.map((station) => ({
      label: station.name,
      code: station.code,
      value: String(station.id),
    }))
);

export const getCurrentFromStation = createSelector<
  ApplicationState,
  FilterState,
  StationObject[],
  StationObjectOrNull
>(
  [filterSelector, getStationObjects],
  (filter, stations) => stations.find((station) => station.value === filter.fromId) || null
);

export const getCurrentToStations = createSelector<ApplicationState, FilterState, StationObject[], StationObject[]>(
  [filterSelector, getStationObjects],
  (filter, stations) => stations.filter((station) => filter.toId.includes(station.value))
);

export const getFromStations = createSelector<ApplicationState, Station[], StationObjectOrNull, Station[]>(
  [stationsSelector, getCurrentFromStation],
  (stations, currentFromStation) => {
    if (currentFromStation === null) {
      return stations;
    }

    const fromStation = stations.find((station) => String(station.id) === currentFromStation.value);

    if (fromStation === undefined) {
      return stations;
    }

    return [fromStation];
  }
);

const filterFromStation =
  (currentFromStation: StationObject) =>
  (stations: Station[]): Station[] =>
    stations.filter((station) => String(station.id) !== currentFromStation.value);

export const getToStations = createSelector<
  ApplicationState,
  Station[],
  StationObject[],
  StationObjectOrNull,
  Station[]
>(
  [stationsSelector, getCurrentToStations, getCurrentFromStation],
  (stations, currentToStations, currentFromStation) => {
    if (currentFromStation === null) {
      return stations;
    }

    if (isEmpty(currentToStations)) {
      return filterFromStation(currentFromStation)(stations);
    }

    const toIds = currentToStations.map((to) => to.value);
    const filteredFrom = filterFromStation(currentFromStation)(stations);
    const filtered = filteredFrom.filter((station) => toIds.includes(String(station.id)));

    return isEmpty(filtered) ? filteredFrom : filtered;
  }
);

export const isFromStationFilterSelected = createSelector<ApplicationState, StationObjectOrNull, boolean>(
  [getCurrentFromStation],
  (currentFromStation) => currentFromStation !== null
);
