import { FareClass } from '../utils/types';

export const SET_VISIBLE_FARE_CLASSES = 'SET_VISIBLE_FARE_CLASSES';

export type SetVisibleFareClassesAction = {
  type: typeof SET_VISIBLE_FARE_CLASSES;
  visibleFareClasses: FareClass[];
};

export const setVisibleFareClasses = (visibleFareClasses: FareClass[]): SetVisibleFareClassesAction => ({
  type: SET_VISIBLE_FARE_CLASSES,
  visibleFareClasses,
});
