import { faBan, faCheck, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cancelEditing, startEditing } from '../../actions/edit';
import { setEditOptions } from '../../actions/editOptions';
import { prepareSave } from '../../actions/save';
import { editorOptionsShape } from '../../utils/propTypes';
import { trans } from '../../utils/Translator';

import styles from './EditOptions.module.scss';

const IsSaving = ({ visible }) =>
  visible ? (
    <span>
      <FontAwesomeIcon icon={faSpinner} spin />
      {trans('Saving...')}
    </span>
  ) : null;

const EditButton = ({ visible, onClick }) =>
  visible ? (
    <button type="button" className={styles.edit} onClick={onClick}>
      <FontAwesomeIcon icon={faEdit} />
      {trans('Edit')}
    </button>
  ) : null;

const EditConfig = ({
  visible,
  saveCall,
  cancelEditingCall,
  editOptions,
  onAutoConvertChange,
  onMirrorPricesChange,
}) =>
  visible ? (
    <div className={styles.inline}>
      <span>
        <button type="button" className={styles.save} onClick={saveCall}>
          <FontAwesomeIcon icon={faCheck} />
          {trans('Save')}
        </button>
        <button type="button" className={styles.cancel} onClick={cancelEditingCall}>
          <FontAwesomeIcon icon={faBan} />
          {trans('Cancel')}
        </button>
      </span>
      <span className={styles.mirror}>
        <label>
          <input type="checkbox" checked={editOptions.mirrorPrices} onChange={onMirrorPricesChange} />
          Mirror Prices
        </label>
      </span>
      <span className={styles.auto_convert}>
        <label>
          <input type="checkbox" checked={editOptions.autoConvert} onChange={onAutoConvertChange} />
          {trans('Auto-convert currency')}
        </label>
      </span>
    </div>
  ) : null;

class EditOptions extends React.PureComponent {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    startEditingCall: PropTypes.func.isRequired,
    saveCall: PropTypes.func.isRequired,
    cancelEditingCall: PropTypes.func.isRequired,
    editOptions: editorOptionsShape.isRequired,
    setEditOptions: PropTypes.func.isRequired,
  };

  onMirrorPricesChange = (event) => {
    this.props.setEditOptions({ mirrorPrices: event.target.checked });
  };

  onAutoConvertChange = (event) => {
    this.props.setEditOptions({ autoConvert: event.target.checked });
  };

  render() {
    const { isEditing, isSaving, startEditingCall, saveCall, cancelEditingCall, editOptions } = this.props;

    return (
      <div className={styles.inline}>
        <IsSaving visible={isSaving} />
        <EditButton onClick={startEditingCall} visible={!isSaving && !isEditing} />
        <EditConfig
          visible={isEditing && !isSaving}
          saveCall={saveCall}
          cancelEditingCall={cancelEditingCall}
          editOptions={editOptions}
          onAutoConvertChange={this.onAutoConvertChange}
          onMirrorPricesChange={this.onMirrorPricesChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEditing: state.isEditing,
  isSaving: state.isSaving,
  editOptions: state.editOptions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingCall: startEditing,
      saveCall: prepareSave,
      cancelEditingCall: cancelEditing,
      setEditOptions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOptions);
