import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { setFilter } from '../../actions/filter';
import { parseToIdsFromUrlString } from '../../utils';

type OwnProps = {
  fromId?: string;
  toId?: string;
  lineId?: string;
};
type StateProps = Record<string, unknown>;
type DispatchProps = {
  setFilter: typeof setFilter;
  push: typeof push;
};
type ComponentProps = OwnProps & StateProps & DispatchProps;

class FilterTracker extends React.PureComponent<ComponentProps> {
  public componentDidMount() {
    const { fromId, toId, lineId } = this.props;

    this.props.setFilter({
      type: 'normal',
      subType: 1,
      fromId,
      toId: parseToIdsFromUrlString(toId || ''),
    });

    // change link to just line
    // TODO: remove this hack
    if (lineId && fromId) {
      this.props.push(`/line/${lineId}`);
    }
  }

  // TODO: think about hot to update this stuff
  // public componentDidUpdate(prevProps: ComponentProps) {
  //   const { fromId, toId } = this.props;
  //
  //   this.props.setFilter({
  //     fromId,
  //     toId: parseToIdsFromUrlString(toId || ''),
  //   });
  // }

  public componentWillUnmount() {
    this.props.setFilter({
      type: undefined,
      subType: undefined,
      fromId: undefined,
      toId: [],
    });
  }

  public render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators({ setFilter, push }, dispatch);

export default connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps)(FilterTracker);
