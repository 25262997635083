import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { Login, Logout } from '../features/auth';

import ProtectedArea from './ProtectedArea';

const App: React.FC = () => (
  <>
    <AuthenticatedTemplate>
      <Switch>
        <Route path="/logout" exact component={Logout} />
        <Route component={ProtectedArea} />
        <Redirect to="/" from="/login" />
      </Switch>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </>
);

export default withRouter(App);
