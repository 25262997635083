import PropTypes from 'prop-types';
import React from 'react';

import { trans } from '../../utils/Translator';

import styles from './LineDeactivation.module.scss';

export class LineDeactivation extends React.PureComponent {
  static propTypes = {
    deactivated: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChange = (event) => {
    this.props.onChange(!event.target.checked);
  };

  render() {
    const { deactivated, isEditing } = this.props;

    if (!isEditing) {
      return null;
    }

    return (
      <label className={styles.deactivate} title={trans('Uncheck to deactivate price for this line')}>
        <input type="checkbox" className={styles.input} checked={deactivated} onChange={this.onChange} />
        {trans('Deactivate for line')}
      </label>
    );
  }
}

export default LineDeactivation;
