const NotFoundException = () => (
  <div>
    <h1>Not Found 404</h1>
    <p>
      <strong>Try something else</strong>
    </p>
  </div>
);

export default NotFoundException;
