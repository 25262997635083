import { LinesPayload } from '../utils/types';

export const LINES_LOADED = 'LINES_LOADED';
export const LINES_FAILED = 'LINES_FAILED';

export type LinesLoadedAction = {
  type: typeof LINES_LOADED;
  payload: LinesPayload;
};

export type LinesFailedAction = {
  type: typeof LINES_FAILED;
  payload: string;
};

export const loadLinesSucceeded = (payload: LinesPayload): LinesLoadedAction => ({
  type: LINES_LOADED,
  payload,
});
export const loadLinesFailed = (payload: string): LinesFailedAction => ({
  type: LINES_FAILED,
  payload,
});
