import { createSelector } from 'reselect';

import { VisibleCurrenciesState } from '../reducers/visibleCurrencies';
import { ApplicationState, SelectOptionItem } from '../utils/types';

import { getVisibleCurrencies } from './stateSelectors';

type VisibleCurrenciesObjects = SelectOptionItem[];

export const getVisibleCurrenciesObjects = createSelector<
  ApplicationState,
  VisibleCurrenciesState,
  VisibleCurrenciesObjects
>([getVisibleCurrencies], (visibleCurrencies) =>
  visibleCurrencies.map((currency) => ({
    label: currency,
    value: currency,
  }))
);
