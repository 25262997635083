import { css } from 'emotion';
import React from 'react';

export default class MultiValue extends React.PureComponent {
  render() {
    const { children, className, components, cx, data, getStyles, innerProps, isDisabled, removeProps, selectProps } =
      this.props;

    const { Container, Label, Remove } = components;

    const containerInnerProps = {
      className: cx(
        css(getStyles('multiValue', this.props)),
        {
          'multi-value': true,
          'multi-value--is-disabled': isDisabled,
        },
        className
      ),
      ...innerProps,
    };

    const labelInnerProps = {
      className: cx(
        css(getStyles('multiValueLabel', this.props)),
        {
          'multi-value__label': true,
        },
        className
      ),
    };

    const removeInnerProps = {
      className: cx(
        css(getStyles('multiValueRemove', this.props)),
        {
          'multi-value__remove': true,
        },
        className
      ),
      ...removeProps,
    };

    return (
      <Container data={data} innerProps={containerInnerProps} selectProps={selectProps}>
        <Remove data={data} innerProps={removeInnerProps} selectProps={selectProps} />
        <Label data={data} innerProps={labelInnerProps} selectProps={selectProps}>
          {children}
        </Label>
      </Container>
    );
  }
}
