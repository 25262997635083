import { ApplicationAction } from '../actions';
import { SET_VISIBLE_CURRENCIES } from '../actions/visibleCurrencies';
import { BASE_CURRENCY } from '../constants/parameters';

export type VisibleCurrenciesState = string[];

const initialState: VisibleCurrenciesState = [BASE_CURRENCY];

export default (state: VisibleCurrenciesState = initialState, action: ApplicationAction): VisibleCurrenciesState => {
  switch (action.type) {
    case SET_VISIBLE_CURRENCIES:
      return [BASE_CURRENCY, ...action.currencies.filter((currency) => currency !== BASE_CURRENCY)];

    default:
      return state;
  }
};
