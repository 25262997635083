import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';

import { setFilter } from '../../actions/filter';
import { getStationObjects } from '../../selectors/stationSelector';
import { valueShape } from '../../utils/propTypes';
import { clearIndicator, container, menu, multiValueLabel, multiValueRemove } from '../../utils/reactSelectStyles';
import { trans } from '../../utils/Translator';
import MultiValue from '../common/MultiValue';

import StationOption from './Option/StationOption';
import StationMultiValueLabel from './Value/StationMultiValueLabel';
import StationSingleValue from './Value/StationSingleValue';

import styles from './StationSelector.module.scss';

const Placeholder = () => <span>{trans('Select station')}</span>;
const DisabledPlaceholder = () => <span>{trans('Please select FROM station first')}</span>;

const customStyles = {
  menu: (base) => ({
    ...base,
    ...menu,
  }),
  container: (base) => ({
    ...base,
    ...container,
  }),
  multiValueLabel: (base) => ({
    ...base,
    ...multiValueLabel,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
    color: base.color,
    fontSize: '100%',
  }),
  multiValueRemove: (base) => ({
    ...base,
    ...multiValueRemove,
    ':hover': {
      background: '#5cac00',
      borderColor: '#5cac00',
      color: '#fff',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    ...clearIndicator,
  }),
};

export class StationSelector extends React.PureComponent {
  static propTypes = {
    stations: PropTypes.arrayOf(valueShape).isRequired,
    selected: PropTypes.oneOfType([valueShape, PropTypes.arrayOf(valueShape)]),
    label: PropTypes.string.isRequired,
    multi: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { stations, selected, label, multi, handleChange, disabled } = this.props;

    return (
      <div className={styles.selector}>
        <p className={styles.label}>{label}</p>
        <Select
          components={{
            Option: StationOption,
            SingleValue: StationSingleValue,
            MultiValueLabel: StationMultiValueLabel,
            MultiValue,
          }}
          styles={customStyles}
          onChange={handleChange}
          options={stations}
          placeholder={disabled ? <DisabledPlaceholder /> : <Placeholder />}
          hideSelectedOptions
          value={selected}
          isMulti={multi}
          isDisabled={disabled}
          isClearable
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stations: getStationObjects(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setFilter }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StationSelector);
