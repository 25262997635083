import { ApplicationAction } from '../actions';
import { INITIAL_STATE_LOADED } from '../actions/initial';
import { Currency } from '../utils/types';

type CurrenciesState = {
  label: string;
  value: string;
}[];

const prepareCurrencies = (data: Currency[]) =>
  data.map((currency) => ({
    label: currency,
    value: currency,
  }));

const initialState: CurrenciesState = [];

export default (state: CurrenciesState = initialState, action: ApplicationAction): CurrenciesState => {
  switch (action.type) {
    case INITIAL_STATE_LOADED:
      return prepareCurrencies(action.payload.currencies);

    default:
      return state;
  }
};
