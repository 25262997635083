import { InitialPayload } from '../utils/types';

export const INIT_APPLICATION = 'INIT_APPLICATION';
export const INITIAL_STATE_LOADED = 'INITIAL_STATE_LOADED';
export const INITIAL_STATE_ERROR = 'INITIAL_STATE_ERROR';

export type InitAction = {
  type: typeof INIT_APPLICATION;
};

export type InitializedAction = {
  type: typeof INITIAL_STATE_LOADED;
  payload: InitialPayload;
};

export type InitializationFailedAction = {
  type: typeof INITIAL_STATE_ERROR;
  payload: string;
};

export const initApplication = (): InitAction => ({ type: INIT_APPLICATION });
export const initializationSucceeded = (payload: InitialPayload): InitializedAction => ({
  type: INITIAL_STATE_LOADED,
  payload,
});
export const initializationFailed = (payload: string): InitializationFailedAction => ({
  type: INITIAL_STATE_ERROR,
  payload,
});
