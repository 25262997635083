const isDeleted = (price) => price.base_price === null;
const isUpdated = (price) => !isDeleted(price);

const convertToObject = (price) => ({
  id: String(price.base_price.id),
  fromId: price.base_price.from,
  toId: price.base_price.to,
  type: price.base_price.type,
  subType: price.base_price.subtype,
  active: price.base_price.active,
  class: price.base_price.class,
  lineClass:
    price.base_price.line_class > 0
      ? {
          class: price.base_price.line_class,
          active: price.base_price.line_class_active,
        }
      : {
          class: '',
          active: true,
        },
  prices: [price.base_price, ...Object.values(price.additional_prices)].reduce(
    (acc, price) => ({
      ...acc,
      [price.currency]: {
        id: String(price.id),
        value: price.price,
      },
    }),
    {}
  ),
});

export const parseSaveResponseSelector = (response) => response.filter(isUpdated).map(convertToObject);
