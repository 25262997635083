import * as ActionTypes from '../constants/ActionTypes';
import { PriceUpdateTypes } from '../constants/PriceUpdateTypes';
import { mirroredKey } from '../utils/priceKey';

function updatePrice(updateType, key) {
  return {
    type: ActionTypes.PriceActionTypes.UPDATE_PRICE,
    updateType,
    key,
    isMirrored: false,
  };
}

export function mirroredPriceAction(action) {
  return {
    ...action,
    key: mirroredKey(action.key),
    isMirrored: true,
  };
}

export function setPriceValue(key, currency, value) {
  return {
    ...updatePrice(PriceUpdateTypes.SET_VALUE, key),
    currency,
    value,
  };
}

export function setCCValue(key, value) {
  return {
    ...updatePrice(PriceUpdateTypes.SET_CC, key),
    value,
  };
}

export function setLCValue(key, value) {
  return {
    ...updatePrice(PriceUpdateTypes.SET_LC, key),
    value,
  };
}

export function setPriceActivate(key, value) {
  return {
    ...updatePrice(PriceUpdateTypes.SET_ACTIVE, key),
    value,
  };
}

export function setPriceLineDeactivate(key, value) {
  return {
    ...updatePrice(PriceUpdateTypes.SET_LINE_DEACTIVE, key),
    value,
  };
}

export function setBatchPrices(prices) {
  return {
    type: ActionTypes.PriceActionTypes.BATCH_UPDATE,
    updateType: PriceUpdateTypes.SET_VALUE,
    prices,
  };
}

export const deletePrice = (key) => updatePrice(PriceUpdateTypes.DELETE, key);
export const unDeletePrice = (key) => updatePrice(PriceUpdateTypes.UNDELETE, key);

export function setFilteredPrices(prices) {
  return { type: ActionTypes.PriceActionTypes.SET_FILTERED_PRICES, prices };
}
