import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { getNumber } from '../../utils';
import { PriceInput } from '../common/PriceInput';

import styles from './Price.module.scss';

class Price extends React.PureComponent {
  onChange = (value) => {
    this.props.onChange(this.props.currency, value);
  };

  render() {
    const { currency, value, isEditing = false, unsaved = false } = this.props;

    const inputClassName = classNames(styles.input, {
      [styles.unsaved]: unsaved,
    });

    const currencyClassName = classNames(styles.currency, {
      [styles.editing]: isEditing,
    });

    return (
      <div className={styles.price}>
        {isEditing && <PriceInput inputClassName={inputClassName} onChange={this.onChange} value={value} />}
        {!isEditing && <span className={styles.value}>{getNumber(value)}</span>}
        <span className={currencyClassName}>{currency}</span>
      </div>
    );
  }
}

Price.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  unsaved: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Price;
