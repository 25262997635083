import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';

import { setVisibleFareClasses } from '../../actions/visibleFareClasses';
import { FareTypeObjects } from '../../constants/FareTypes';
import { getVisibleFareClassesFilterValues } from '../../selectors/visibleFareClassesSelector';
import { valueShape } from '../../utils/propTypes';
import { clearIndicator, container, menu, multiValueLabel, multiValueRemove } from '../../utils/reactSelectStyles';
import { trans } from '../../utils/Translator';
import MultiValue from '../common/MultiValue';

import styles from './FareClassSelector.module.scss';

const Placeholder = () => <span>{trans('Select fare classes')}</span>;
const DisabledPlaceholder = () => <span>{trans('Please select FROM station first')}</span>;

const customStyles = {
  menu: (base) => ({
    ...base,
    ...menu,
  }),
  container: (base) => ({
    ...base,
    ...container,
  }),
  multiValueLabel: (base) => ({
    ...base,
    ...multiValueLabel,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ...multiValueRemove,
  }),
  clearIndicator: (base) => ({
    ...base,
    ...clearIndicator,
  }),
};

export class FareClassSelector extends React.PureComponent {
  static propTypes = {
    initialized: PropTypes.bool.isRequired,
    setVisibleFareClasses: PropTypes.func.isRequired,
    visibleFareClasses: PropTypes.arrayOf(valueShape).isRequired,
    availableFareClasses: PropTypes.arrayOf(valueShape).isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    disabled: false,
    availableFareClasses: Object.values(FareTypeObjects).map((f) => ({ value: f.key, label: f.label })),
  };

  handleChange = (value) => {
    this.props.setVisibleFareClasses(value.map((item) => ({ ...FareTypeObjects[item.value] })));
  };

  render() {
    const { initialized, availableFareClasses, visibleFareClasses, disabled } = this.props;

    return (
      <div className={styles.selector}>
        <p className={styles.label}>{trans('Filter by fare class')}</p>
        <Select
          styles={customStyles}
          components={{
            MultiValue,
          }}
          isMulti
          onChange={this.handleChange}
          options={availableFareClasses}
          placeholder={disabled ? <DisabledPlaceholder /> : <Placeholder />}
          hideSelectedOptions
          value={visibleFareClasses}
          isLoading={!initialized}
          isClearable
          isDisabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visibleFareClasses: getVisibleFareClassesFilterValues(state),
  initialized: state.initialized.initialized,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setVisibleFareClasses }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FareClassSelector);
