import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setFilter } from '../../actions/filter';
import { getCurrentLineId } from '../../selectors/lineSelector';
import { getCurrentFromStation, getCurrentToStations } from '../../selectors/stationSelector';
import { valueShape } from '../../utils/propTypes';
import { trans } from '../../utils/Translator';
import CurrencySelector from '../CurrencySelector';
import FareClassSelector from '../FareClassSelector/FareClassSelector';
import LineSelector from '../LineSelector/LineSelect/LineSelect';
import StationSelector from '../StationSelector/StationSelector';

import styles from './ControlPanel.module.scss';

export class ControlPanel extends React.Component {
  static propTypes = {
    lineId: PropTypes.string,
    selectedFrom: valueShape,
    selectedTo: PropTypes.arrayOf(valueShape),
  };

  handleFromChange = (value) => {
    this.props.setFilter({ fromId: get(value, 'value', null) });
  };

  handleToChange = (value) => {
    this.props.setFilter({ toId: value.map((item) => item.value) });
  };

  render() {
    const { lineId, selectedFrom, selectedTo } = this.props;

    if (!lineId) {
      return (
        <div className={styles.panel}>
          <LineSelector />
        </div>
      );
    }

    return (
      <div className={styles.panel}>
        <LineSelector />
        <CurrencySelector />
        <div className={styles.from_station}>
          <StationSelector
            key="from"
            label={trans('From station')}
            multi={false}
            handleChange={this.handleFromChange}
            selected={selectedFrom}
          />
        </div>
        <div className={styles.to_station}>
          <StationSelector
            key="to"
            label={trans('To stations')}
            multi
            handleChange={this.handleToChange}
            selected={selectedTo}
            disabled={selectedFrom === null}
          />
        </div>
        <FareClassSelector disabled={selectedFrom === null} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lineId: getCurrentLineId(state),
  selectedFrom: getCurrentFromStation(state),
  selectedTo: getCurrentToStations(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setFilter }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
