import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';

import { lineObjectShape } from '../../../utils/propTypes';
import Line from '../Line/Line';

export default class SingleValue extends React.PureComponent {
  static propTypes = {
    data: lineObjectShape,
    className: PropTypes.string,
    cx: PropTypes.func,
    getStyles: PropTypes.func,
    isDisabled: PropTypes.bool,
    innerProps: PropTypes.object,
  };

  render() {
    const { data, className, cx, getStyles, isDisabled, innerProps } = this.props;

    return (
      <div
        className={cx(
          css(getStyles('singleValue', this.props)),
          {
            'single-value': true,
            'single-value--is-disabled': isDisabled,
          },
          className
        )}
        {...innerProps}
      >
        <Line
          backgroundColor={data.attributes.bg_color}
          color={data.attributes.fg_color}
          code={data.attributes.code}
          label={data.label}
        />
      </div>
    );
  }
}
