import { ApplicationAction } from '../actions';
import { INIT_APPLICATION } from '../actions/initial';
import { LINES_FAILED, LINES_LOADED } from '../actions/lines';
import { Line, LinesPayload } from '../utils/types';

export type LinesState = {
  list: Line[];
  error: string | null;
  isLoading: boolean;
};

const initialState: LinesState = { list: [], error: null, isLoading: false };

const updateLines = (payload: LinesPayload) =>
  payload.map((line) => ({
    value: line.id,
    label: line.text,
    longText: `${line.attributes.code} ${line.text}`,
    attributes: line.attributes,
  }));

export default (state: LinesState = initialState, action: ApplicationAction): LinesState => {
  switch (action.type) {
    case INIT_APPLICATION:
      return { ...initialState };
    case LINES_LOADED:
      return {
        ...initialState,
        list: updateLines(action.payload),
      };
    case LINES_FAILED:
      return { ...initialState, error: action.payload };

    default:
      return state;
  }
};
