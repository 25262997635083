import { getNormalFareClassFromSubType } from './fareType';
import { hasInactiveLC, hasLC, isActivePrice, isNormalPrice, isPromotionalPrice } from './price.utils';
import { Conflict, Price } from './types';

export const getLcConflictsInPricesArray = (prices: Price[]): Conflict[] =>
  prices.reduce<Conflict[]>((acc, price, i, pricesArr) => {
    if (!isActivePrice(price) || !isNormalPrice(price) || isPromotionalPrice(price)) {
      return acc;
    }

    const pricesToCheck = pricesArr.slice(i + 1);

    if (pricesToCheck.length === 0) {
      return acc;
    }

    const currentFareClass = getNormalFareClassFromSubType(price.subType);

    if (!currentFareClass) {
      // this should never happen. If happens then the data is screwed up. should  throw error?
      return acc;
    }

    const currentClass = hasLC(price) ? price.lineClass!.class : price.class;

    // if LC is set but deactivate for line is checked, then its completely ignored in calculation
    if (hasInactiveLC(price)) {
      return acc;
    }

    // creating a copy of segmentPrices as its modified inside the loop to break early
    const conflictingPrice = pricesToCheck.reduce<Price | null>((acc, currSp, _, arr) => {
      if (isNormalPrice(currSp) && !isPromotionalPrice(currSp) && isActivePrice(currSp) && !hasInactiveLC(currSp)) {
        const checkingClass = hasLC(currSp) ? currSp.lineClass!.class : currSp.class;

        if (currentClass === checkingClass) {
          acc = currSp;
          arr.splice(1); // break the loop early
        }
      }

      return acc;
    }, null);

    if (conflictingPrice) {
      const conflictingFareClass = getNormalFareClassFromSubType(conflictingPrice.subType);

      acc.push({
        conflictingFareClass: conflictingFareClass!.label,
        priceFareClass: currentFareClass!.label,
        conflictingPriceId: conflictingPrice.id,
        priceId: price.id,
      });
    }

    return acc;
  }, []);
