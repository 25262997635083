import { createSelector } from 'reselect';

import { isValidCC, isValidLC } from '../utils/price.utils';
import { createKey } from '../utils/priceKey';

import { pricesSelector } from './stateSelectors';

const getFromStopIdFromProps = (state, ownProps) => ownProps.fromStop.id;
const getToStopIdFromProps = (state, ownProps) => ownProps.toStop.id;
const getTypeAndSubTypeFromProps = (state, ownProps) => ownProps.fareClass;

const getKeyFromProps = createSelector(
  [getFromStopIdFromProps, getToStopIdFromProps, getTypeAndSubTypeFromProps],
  (fromId, toId, fareClass) => createKey(fromId, toId, fareClass.type, fareClass.subType)
);

const selectPriceFromPrices = createSelector([getKeyFromProps, pricesSelector], (key, prices) =>
  prices.hasOwnProperty(key) ? prices[key] : null
);

export const createGetPriceSelector = () =>
  createSelector(
    [getFromStopIdFromProps, getToStopIdFromProps, getTypeAndSubTypeFromProps, selectPriceFromPrices],
    (fromId, toId, fareClass, price) =>
      price !== null
        ? price
        : {
            fromId: String(fromId),
            toId: String(toId),
            type: fareClass.type,
            subType: fareClass.subType,
            active: false,
            prices: {},
            class: '',
            lineClass: {
              active: true,
              class: '',
            },
          }
  );

export const getIsNewPrice = () => createSelector([selectPriceFromPrices], (price) => !Boolean(price));

export const getDistanceFromProps = (state, { fromStop, toStop }) => {
  if (fromStop.distances.hasOwnProperty(fromStop.id) && fromStop.distances[fromStop.id].hasOwnProperty(toStop.id)) {
    return parseFloat(fromStop.distances[fromStop.id][toStop.id]);
  }

  return 0;
};

export const isPriceValidSelector = () =>
  createSelector([selectPriceFromPrices], (price) => price === null || (isValidCC(price) && isValidLC(price)));
