import { connect } from 'react-redux';

import { isFromStationFilterSelected } from '../../selectors/stationSelector';

import NewTable from './NewTable';
import OldTable from './OldTable';

const MatrixView = ({ isFromStationSelected }) => {
  if (isFromStationSelected) {
    return <NewTable />;
  }

  return <OldTable />;
};

const mapStateToProps = (state) => ({
  isFromStationSelected: isFromStationFilterSelected(state),
});

export default connect(mapStateToProps)(MatrixView);
