import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

export const createMsalInstance = (msalConfig: Configuration) => {
  const instance = new PublicClientApplication(msalConfig);

  const accounts = instance.getAllAccounts();

  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;

      instance.setActiveAccount(account);
    }
  });

  return instance;
};
