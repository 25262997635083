import { ApplicationAction } from '../actions';
import { CLEAR_LINE, LOAD_LINE_BY_ID_SUCCESS } from '../actions/line';
import { SAVING_SUCCESS } from '../actions/save';
import priceKey from '../utils/priceKey';
import { Price } from '../utils/types';

const pricesMap = (prices: Price[]) =>
  prices.reduce(
    (acc, price) => ({
      ...acc,
      [priceKey(price)]: price,
    }),
    {}
  );

const notDeletedFilter = (deletedKeys: string[]) => {
  const keysSet = new Set(deletedKeys);

  return (price: Price) => !keysSet.has(priceKey(price));
};

export type RepositoryState = Price[];

const initialState: RepositoryState = [];

export default (state: RepositoryState = initialState, action: ApplicationAction): RepositoryState => {
  switch (action.type) {
    case SAVING_SUCCESS:
      return Object.values({
        ...pricesMap(state.filter(notDeletedFilter(action.deletedPriceKeys))),
        ...pricesMap(action.updatedPrices),
      });
    case LOAD_LINE_BY_ID_SUCCESS:
      return action.payload.prices;
    case CLEAR_LINE:
      return [];

    default:
      return state;
  }
};
