import PropTypes from 'prop-types';
import React from 'react';
import { FixedSizeList } from 'react-window';

import { lineObjectShape } from '../../../utils/propTypes';

const height = 35;

export default class MenuList extends React.PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(lineObjectShape).isRequired,
    children: PropTypes.node.isRequired,
    maxHeight: PropTypes.number.isRequired,
    getValue: PropTypes.func.isRequired,
  };

  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <FixedSizeList
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    );
  }
}
