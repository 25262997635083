import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clearLine, loadLineById } from '../actions/line';
import Matrix from '../components/Matrix';
import FilterTracker from '../components/renderless/FilterTracker';
import { CurrentLineState } from '../reducers/currentLine';
import { getLineDataFromTheURL } from '../selectors/lineSelector';
import { ApplicationState, LineDataFromURL } from '../utils/types';

import PageLayout from './PageLayout';

type OwnProps = {
  match: {
    params: {
      [key: string]: string;
    };
  };
};
type StateProps = {
  lineData: LineDataFromURL;
  currentLine: CurrentLineState;
};
type DispatchProps = {
  loadLineById: typeof loadLineById;
  clearLine: typeof clearLine;
};
type ComponentProps = OwnProps & StateProps & DispatchProps;

class MatrixContainer extends React.PureComponent<ComponentProps> {
  public componentDidMount() {
    this.props.loadLineById(this.props.lineData.lineId);
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    const {
      lineData: { lineId },
    } = this.props;

    if (prevProps.lineData.lineId !== lineId) {
      this.props.loadLineById(lineId);
    }
  }

  public componentWillUnmount() {
    this.props.clearLine();
  }

  public render() {
    const {
      currentLine: { loaded, error },
      lineData: { fromId, toId, lineId },
    } = this.props;

    if (!loaded) {
      if (error) {
        return <div className="fare-classes">Error occurred while line load: {error}</div>;
      }

      return <div className="fare-classes">Loading...</div>;
    }

    return (
      <PageLayout>
        <FilterTracker fromId={fromId} toId={toId} lineId={lineId} />
        <Matrix />
      </PageLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => ({
  lineData: getLineDataFromTheURL(state, ownProps),
  currentLine: state.currentLine,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ loadLineById, clearLine }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MatrixContainer);
