import { ApplicationAction } from '../actions';
import {
  HIDE_FORBIDDEN_RELATIONS,
  LOAD_FORBIDDEN_RELATIONS,
  LOAD_FORBIDDEN_RELATIONS_FAIL,
  LOAD_FORBIDDEN_RELATIONS_SUCCESS,
  SHOW_FORBIDDEN_RELATIONS,
} from '../actions/forbiddenRelations';
import { LINES_GO_TO } from '../actions/goTo';
import { CLEAR_LINE } from '../actions/line';
import { DirectionRulesPayload } from '../utils/types';

export type ForbiddenRelationsState = {
  map: DirectionRulesPayload;
  error: string | null;
  isLoading: boolean;
  isLoaded: boolean;
  visible: boolean;
};

const initialState: ForbiddenRelationsState = {
  map: {},
  error: null,
  isLoading: false,
  isLoaded: false,
  visible: true,
};

export default (state: ForbiddenRelationsState = initialState, action: ApplicationAction): ForbiddenRelationsState => {
  switch (action.type) {
    case HIDE_FORBIDDEN_RELATIONS:
      return { ...state, visible: false };
    case SHOW_FORBIDDEN_RELATIONS:
      return { ...state, visible: true };
    case LOAD_FORBIDDEN_RELATIONS:
      return { ...state, isLoading: !state.isLoaded };
    case LOAD_FORBIDDEN_RELATIONS_SUCCESS:
      return { ...state, map: action.payload, isLoading: false, isLoaded: true };
    case LOAD_FORBIDDEN_RELATIONS_FAIL:
      return { ...state, error: action.payload, isLoaded: false, isLoading: false, visible: true };
    case CLEAR_LINE:
    case LINES_GO_TO:
      return { ...initialState };

    default:
      return state;
  }
};
