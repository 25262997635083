import { PriceKey } from './types';

export const createKey = (fromId: string, toId: string, type: string, subType: number): string =>
  [fromId, toId, type, subType].join(':');

export const createRelationKey = (fromId: string, toId: string): string => [fromId, toId].join(':');

export function splitKey(key: string): PriceKey {
  const parts = key.split(':');

  return {
    fromId: parts[0],
    toId: parts[1],
    type: parts[2],
    subType: parseInt(parts[3], 10),
  };
}

const priceKey = (price: PriceKey): string => createKey(price.fromId, price.toId, price.type, price.subType);

export function mirroredKey(key: string): string {
  const priceParts = splitKey(key);

  return priceKey({
    ...priceParts,
    fromId: priceParts.toId,
    toId: priceParts.fromId,
  });
}

export default priceKey;
