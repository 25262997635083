import get from 'lodash/get';
import { createSelector } from 'reselect';

import priceKey from '../utils/priceKey';

import { pricesSelector } from './stateSelectors';

export const isActiveChanged = (price) => price.oldActive !== undefined && price.active !== price.oldActive;
export const isPriceValueChanged = (price) =>
  Object.values(price.prices).find(
    (currencyPrice) => currencyPrice.oldValue !== undefined && currencyPrice.value !== currencyPrice.oldValue
  ) !== undefined;
export const isDeleted = (price) => Boolean(price.isDeleted);
export const isClassChanged = (price) => price.oldClass !== undefined && price.class !== price.oldClass;
export const isLineClassChanged = ({ lineClass = {} }) =>
  lineClass !== null && lineClass.oldClass !== undefined && lineClass.class !== lineClass.oldClass;
export const isLineDeactiveChanged = ({ lineClass = {} }) =>
  lineClass !== null && lineClass.oldActive !== undefined && lineClass.active !== lineClass.oldActive;

export const savePricesRequestSelector = createSelector(pricesSelector, (prices) =>
  Object.values(prices)
    .filter(
      (price) =>
        isActiveChanged(price) ||
        isPriceValueChanged(price) ||
        isDeleted(price) ||
        isClassChanged(price) ||
        isLineClassChanged(price) ||
        isLineDeactiveChanged(price)
    )
    .map((price) => ({
      id: price.id || null,
      active: price.active,
      data: {
        from: price.fromId,
        to: price.toId,
        type: price.type,
        subtype: price.subType,
      },
      class: !price.isDeleted ? price.class : 0,
      prices: Object.entries(price.prices).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            id: value.id || null,
            price: !price.isDeleted ? value.value : null,
            currency: key,
          },
        }),
        {}
      ),
      line_class: get(price, 'lineClass.class', 0),
      line_class_active: get(price, 'lineClass.active', true),
    }))
);

const isPriceDeletedInRequest = (price) => price.class === 0 && price.prices.EUR.price === null;

export const selectDeletedPriceKeysFromPayload = (payload) =>
  payload.filter(isPriceDeletedInRequest).map((price) =>
    priceKey({
      fromId: price.data.from,
      toId: price.data.to,
      type: price.data.type,
      subType: price.data.subtype,
    })
  );
