export const START_EDITING = 'START_EDITING';
export const CANCEL_EDITING = 'CANCEL_EDITING';

export type StartEditingAction = {
  type: typeof START_EDITING;
};

export type CancelEditingAction = {
  type: typeof CANCEL_EDITING;
};

export const startEditing = (): StartEditingAction => ({ type: START_EDITING });
export const cancelEditing = (): CancelEditingAction => ({ type: CANCEL_EDITING });
