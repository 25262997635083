import PropTypes from 'prop-types';

export const valueShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const fareClassShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  subType: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const stopShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  distances: PropTypes.object.isRequired,
});

export const stopOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const additionalPriceShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const lineClassShape = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  oldActive: PropTypes.bool,
  class: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  oldClass: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const priceShape = PropTypes.shape({
  fromId: PropTypes.string.isRequired,
  toId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subType: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  oldActive: PropTypes.bool,
  class: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  oldClass: PropTypes.number,
  prices: PropTypes.objectOf(additionalPriceShape).isRequired,
  lineClass: lineClassShape.isRequired,
  isDeleted: PropTypes.bool,
});

export const lineObjectShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    bg_color: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    fg_color: PropTypes.string.isRequired,
  }).isRequired,
  longText: PropTypes.string.isRequired,
});

export const editorOptionsShape = PropTypes.shape({
  mirrorPrices: PropTypes.bool.isRequired,
  autoConvert: PropTypes.bool.isRequired,
});
