import { get, post } from './transport';
import {
  DirectionRulesPayload,
  InitialPayload,
  LineDataPayload,
  LineId,
  LinesPayload,
  SavedPricesPayload,
  SavePayload,
} from './types';

type InitialResponse = InitialPayload;

export const fetchInitializationData = async (options?: RequestInit): Promise<InitialPayload> =>
  get<InitialResponse>('/api/pricing/fare-management-legacy/init', options);

type LinesResponse = {
  result: LinesPayload;
};

export const fetchLinesData = async (options?: RequestInit): Promise<LinesPayload> =>
  (await get<LinesResponse>('/api/pricing/fare-management-legacy/all-lines', options)).result;

type LineDataResponse = LineDataPayload;

export const fetchLineData = async (lineId: LineId, options?: RequestInit): Promise<LineDataPayload> =>
  get<LineDataResponse>(`/api/pricing/fare-management-legacy/line/${lineId}`, options);

type SavedPricesResponse = SavedPricesPayload;

export const postLineData = async (
  lineId: LineId,
  data: SavePayload,
  options?: RequestInit
): Promise<SavedPricesPayload> =>
  post<SavedPricesResponse, SavePayload>(`/api/pricing/fare-management-legacy/bulk-save/${lineId}`, data, options);

type DirectionRulesResponse = DirectionRulesPayload;

export const fetchLineDirectionRules = async (lineId: LineId, options?: RequestInit): Promise<DirectionRulesPayload> =>
  get<DirectionRulesResponse>(`/api/lines/${lineId}/direction-rules`, options);
