import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';

import { setVisibleCurrencies } from '../../actions/visibleCurrencies';
import { getVisibleCurrenciesObjects } from '../../selectors/currencySelector';
import { valueShape } from '../../utils/propTypes';
import { clearIndicator, container, menu, multiValueLabel, multiValueRemove } from '../../utils/reactSelectStyles';
import { trans } from '../../utils/Translator';
import MultiValue from '../common/MultiValue';

import styles from './CurrencySelector.module.scss';

const customStyles = {
  menu: (base) => ({
    ...base,
    ...menu,
  }),
  container: (base) => ({
    ...base,
    ...container,
  }),
  multiValueLabel: (base) => ({
    ...base,
    ...multiValueLabel,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ...multiValueRemove,
  }),
  clearIndicator: (base) => ({
    ...base,
    ...clearIndicator,
  }),
};

export class CurrencySelector extends React.PureComponent {
  static propTypes = {
    currencies: PropTypes.arrayOf(valueShape).isRequired,
    initialized: PropTypes.bool.isRequired,
    setVisibleCurrencies: PropTypes.func.isRequired,
    visibleCurrencies: PropTypes.arrayOf(valueShape).isRequired,
  };

  handleChange = (value) => {
    this.props.setVisibleCurrencies(value.map((currency) => currency.value));
  };

  render() {
    const { initialized, currencies, visibleCurrencies } = this.props;

    return (
      <div className={styles.selector}>
        <p className={styles.label}>{trans('Additional currencies')}</p>
        <Select
          styles={customStyles}
          components={{
            MultiValue,
          }}
          isMulti
          onChange={this.handleChange}
          options={currencies}
          hideSelectedOptions
          value={visibleCurrencies}
          isLoading={!initialized}
          isClearable
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currencies: state.currencies,
  visibleCurrencies: getVisibleCurrenciesObjects(state),
  initialized: state.initialized.initialized,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setVisibleCurrencies }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
