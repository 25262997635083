import { call, put, takeLatest } from 'redux-saga/effects';

import {
  handleSavingError,
  handleSavingSuccess,
  SAVE,
  SaveAction,
  SavingErrorAction,
  SavingSuccessAction,
} from '../actions/save';
import { parseSaveResponseSelector } from '../selectors/parseSaveResponseSelector';
import { selectDeletedPriceKeysFromPayload } from '../selectors/savePricesRequestSelector';
import { postLineData } from '../utils/api';
import { errorReport } from '../utils/datadog';
import { SavedPricesPayload } from '../utils/types';

export function* savePrices({ payload, lineId }: SaveAction) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: SavedPricesPayload = yield call(postLineData, lineId, payload);

    try {
      const updatedPrices = parseSaveResponseSelector(response);
      const deletedKeys = selectDeletedPriceKeysFromPayload(payload);

      yield put<SavingSuccessAction>(handleSavingSuccess(updatedPrices, deletedKeys));
    } catch (error) {
      errorReport(`Exception during updating the state after line ${lineId} save`, { error });
      alert(
        'Changes were saved, but we encountered an error while updating the state.\n\n' +
          'Page will be refreshed! We are apologizing for that.'
      ); // Sorry
      window.location.reload();
    }
  } catch (error) {
    errorReport(`Exception during line ${lineId} save`, { error });
    yield put<SavingErrorAction>(handleSavingError());
    alert(`Error: ${(error as Error).message || error}`);
  }
}

export default function* savePricesSaga() {
  yield takeLatest<SaveAction>(SAVE, savePrices);
}
