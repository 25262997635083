import { createSelector } from 'reselect';

import { ForbiddenRelationsState } from '../reducers/forbiddenRelations';
import { isDateTimeWithinDateRange } from '../utils';
import { ApplicationState, DirectionRule, DirectionRulesPayload, DirectionRuleTimeFrame } from '../utils/types';

import { getForbiddenRelationsSelector } from './stateSelectors';

const isCurrentlyWithinTimeFrame = (dateTime: number) => (timeFrame: DirectionRuleTimeFrame) =>
  isDateTimeWithinDateRange(dateTime, timeFrame.from, timeFrame.till);

const ruleHasCurrentlyActiveTimeFrame = (rule: DirectionRule) =>
  rule.timeframes.length === 0 || rule.timeframes.some(isCurrentlyWithinTimeFrame(Date.now()));

type DirectionRulesFrom = {
  [key: string]: DirectionRule;
};

const filterRelation = (rules: DirectionRulesFrom) => (to: string) =>
  ruleHasCurrentlyActiveTimeFrame(rules[to]) && !rules[to].allowed;

const prepareForbiddenRelations = (directionRules: DirectionRulesPayload) =>
  Object.keys(directionRules).reduce(
    (previous, current) => ({
      ...previous,
      [current]: Object.keys(directionRules[current])
        .filter(filterRelation(directionRules[current]))
        .reduce((p, c) => ({ ...p, [c]: true }), {}),
    }),
    {}
  );

export const getForbiddenRelations = createSelector<ApplicationState, ForbiddenRelationsState, DirectionRulesPayload>(
  [getForbiddenRelationsSelector],
  (forbiddedRelationsState) => prepareForbiddenRelations(forbiddedRelationsState.map)
);

export const areForbiddenRelationsLoaded = createSelector<ApplicationState, ForbiddenRelationsState, boolean>(
  [getForbiddenRelationsSelector],
  (state) => state.isLoaded
);

export const areForbiddenRelationsLoading = createSelector<ApplicationState, ForbiddenRelationsState, boolean>(
  [getForbiddenRelationsSelector],
  (state) => state.isLoading
);

export const getForbiddenRelationsError = createSelector<ApplicationState, ForbiddenRelationsState, string | null>(
  [getForbiddenRelationsSelector],
  (state) => state.error
);

export const areForbiddenRelationsVisible = createSelector<ApplicationState, ForbiddenRelationsState, boolean>(
  [getForbiddenRelationsSelector],
  (state) => state.visible
);
