import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { bindActionCreators } from 'redux';

import { goToLine, goToMainPage } from '../../../actions/goTo';
import { getLineById } from '../../../selectors/lineSelector';
import { lineObjectShape } from '../../../utils/propTypes';
import { menu } from '../../../utils/reactSelectStyles';
import { trans } from '../../../utils/Translator';

import MenuList from './MenuList';
import Option from './Option';
import SingleValue from './SingleValue';

import styles from './LineSelect.module.scss';

const Placeholder = () => <span>{trans('Select Line')}</span>;

const customStyles = {
  menu: (base) => ({
    ...base,
    ...menu,
  }),
  container: (base) => ({
    ...base,
    flexGrow: 1,
  }),
};

export class LineSelect extends React.Component {
  static propTypes = {
    line: lineObjectShape,
    lines: PropTypes.arrayOf(lineObjectShape).isRequired,
    isLoading: PropTypes.bool.isRequired,
    goToLine: PropTypes.func.isRequired,
    goToMainPage: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.line,
    manualClear: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.manualClear) {
      return {
        value: null,
        manualClear: false,
      };
    }

    if (state.value === null && props.line !== null) {
      return {
        value: props.line,
      };
    }

    return null;
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  goToLineClick = () => {
    this.props.goToLine(this.state.value.value);
  };

  goToMainPageClick = () => {
    this.setState({ value: null, manualClear: true }, () => {
      this.props.goToMainPage();
    });
  };

  render() {
    const { lines, isLoading } = this.props;

    return (
      <div className={styles.selector}>
        <Select
          components={{ Option, SingleValue, MenuList }}
          styles={customStyles}
          isClearable={false}
          placeholder={<Placeholder />}
          value={this.state.value}
          onChange={this.handleChange}
          options={lines}
          isLoading={isLoading}
          filterOption={createFilter({ stringify: (option) => option.data.longText })}
        />
        <button type="button" className={styles.submit} disabled={!this.state.value} onClick={this.goToLineClick}>
          <FontAwesomeIcon icon={faSearch} /> {trans('Go to line')}
        </button>
        <button type="button" className={styles.reset} onClick={this.goToMainPageClick}>
          <FontAwesomeIcon icon={faTimes} /> {trans('Reset')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lines: state.lines.list,
  isLoading: state.lines.isLoading,
  line: getLineById(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ goToLine, goToMainPage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LineSelect);
