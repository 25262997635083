import PropTypes from 'prop-types';
import React from 'react';

import { PRICE_PATTERN } from '../../constants/parameters';
import { commasToPoints, getNumber, round } from '../../utils';

const KEYCODE_UP = 38;
const KEYCODE_DOWN = 40;

export class PriceInput extends React.Component {
  static propTypes = {
    inputClassName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  };

  state = {
    value: getNumber(this.props.value, ''),
  };

  onChange = (event) => {
    const input = event.target;
    const value = commasToPoints(input.value);

    this.change(value);
  };

  static getDerivedStateFromProps(props, state) {
    const propsValue = parseFloat(props.value);
    const stateValue = parseFloat(state.value);

    if (propsValue !== stateValue) {
      return {
        value: getNumber(props.value, ''),
      };
    }

    return null;
  }

  change = (value) => {
    if (!PRICE_PATTERN.test(value)) {
      return;
    }

    this.setState({ value });

    const amount = parseFloat(value);

    if (isNaN(amount)) {
      this.props.onChange('');

      return;
    }

    const currentValue = parseFloat(this.props.value);

    if (currentValue !== amount) {
      this.props.onChange(amount);
    }
  };

  onKeyDown = (e) => {
    if (e.keyCode === KEYCODE_UP) {
      e.preventDefault();

      const baseValue = getNumber(this.state.value);

      this.change(round(baseValue + 1, 2));
    }
    if (e.keyCode === KEYCODE_DOWN) {
      e.preventDefault();

      const baseValue = getNumber(this.state.value);

      this.change(round(baseValue - 1, 2));
    }
  };

  render() {
    const { inputClassName } = this.props;
    const { value } = this.state;

    return (
      <input className={inputClassName} type="text" value={value} onChange={this.onChange} onKeyDown={this.onKeyDown} />
    );
  }
}
