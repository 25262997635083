import { useIsAuthenticated } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { memo, useEffect } from 'react';

const DatadogSessionReplayRecorder = () => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && process.env.NODE_ENV === 'production') {
      datadogRum.startSessionReplayRecording();
    }
  }, [isAuthenticated]);

  return null;
};

export default memo(DatadogSessionReplayRecorder);
