import { ApplicationAction } from '../actions';
import { INITIAL_STATE_ERROR, INITIAL_STATE_LOADED } from '../actions/initial';

export type InitializedState = {
  readonly initialized: boolean;
  readonly error: string | null;
};

const initialState: InitializedState = { initialized: false, error: null };

export default (state: InitializedState = initialState, action: ApplicationAction): InitializedState => {
  switch (action.type) {
    case INITIAL_STATE_LOADED:
      return { ...initialState, initialized: true };
    case INITIAL_STATE_ERROR:
      return {
        ...initialState,
        error: action.payload,
      };

    default:
      return state;
  }
};
