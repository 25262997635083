import { ApplicationAction } from '../actions';
import { SET_EDIT_OPTIONS } from '../actions/editOptions';

export type EditOptionsState = {
  readonly mirrorPrices: boolean;
  readonly autoConvert: boolean;
};

const initialState: EditOptionsState = { mirrorPrices: true, autoConvert: true };

export default (state: EditOptionsState = initialState, action: ApplicationAction): EditOptionsState => {
  switch (action.type) {
    case SET_EDIT_OPTIONS:
      return { ...state, ...action.editOptions };

    default:
      return state;
  }
};
