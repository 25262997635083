// TODO Find better solution to import the Translator
const translator = () =>
  (window as any).Translator || {
    trans: (input: string): string => input,
    transChoice: (input: string): string => input,
  };

export function trans(input: string) {
  return translator().trans(input);
}

export function transChoice(input: string) {
  return translator().transChoice(input);
}
