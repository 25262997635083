import { apiAccessRequest, msalInstance } from './AuthProvider';

export const acquireAccessToken = async () => {
  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside the context of MsalProvider
     */
    throw new Error('Tried to acquire token before logging in');
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: apiAccessRequest.scopes,
    account: activeAccount || accounts[0],
  });

  return authResult.accessToken;
};
