import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './containers/App';
import { AuthProvider } from './features/auth';
import history from './store/history';
import { DatadogErrorBoundary, DatadogSessionReplayRecorder, initDataDog } from './utils/datadog';
import configureStore from './store';

import './index.scss';

const { store, persistor } = configureStore(history);

// eslint-disable-next-line @typescript-eslint/naming-convention
const render = (Component: React.ComponentType) => {
  ReactDOM.render(
    <DatadogErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <AuthProvider>
              <DatadogSessionReplayRecorder />
              <Component />
            </AuthProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </DatadogErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

if (process.env.NODE_ENV !== 'production') {
  module.hot?.accept('./app/App', () => {
    render(App);
  });
} else {
  initDataDog();
}
