import { useMsal } from '@azure/msal-react';
import { Button, Heading, ImageLink, Text } from '@flixbus/honeycomb-react';
import { startCase } from 'lodash';
import React from 'react';

import { apiAccessRequest } from '../AuthProvider';

import welcomeImage from './welcome.jpg';

import styles from './Login.module.scss';

const appTitle = startCase(process.env.REACT_APP_TITLE);

const LoginComp: React.FC = () => {
  const { instance } = useMsal();
  const handleLogin = () => instance.loginRedirect(apiAccessRequest);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <ImageLink extraClasses={styles.imageLink} href="#" img={{ src: welcomeImage, alt: 'Illuminati' }}>
          <Heading size={1}>Welcome to {appTitle}</Heading>
          <Text>
            Dear visitor,
            <br />
            You need to login in order to continue. Please click in the button below to login to the application. Once
            you click the button a login page will open
          </Text>
          <div className={styles.buttonContainer}>
            <Button appearance="secondary" onClick={handleLogin}>
              Login to {appTitle}
            </Button>
          </div>
        </ImageLink>
      </div>
    </div>
  );
};

export const Login = React.memo(LoginComp);
export default Login;
