export const SET_VISIBLE_CURRENCIES = 'SET_VISIBLE_CURRENCIES';

type Currencies = string[];

export type SetVisibleCurrenciesAction = {
  type: typeof SET_VISIBLE_CURRENCIES;
  currencies: Currencies;
};

export const setVisibleCurrencies = (currencies: Currencies): SetVisibleCurrenciesAction => ({
  type: SET_VISIBLE_CURRENCIES,
  currencies,
});
