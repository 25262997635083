import { createSelector } from 'reselect';

import { BASE_CURRENCY } from '../constants/parameters';
import { ApplicationState, CustomExchange, CustomExchanges } from '../utils/types';

import { customExchangesSelector } from './stateSelectors';

export const createExchangeRatesSelector = (eurAmount: string) =>
  createSelector<ApplicationState, CustomExchanges, CustomExchange>([customExchangesSelector], (customExchanges) => {
    const pick = (left: number, right: number): CustomExchange => {
      if (right <= left) {
        return customExchanges[left];
      }

      const middle = Math.floor(left + (right - left) / 2);
      const middleAmount = customExchanges[middle][BASE_CURRENCY];

      return middleAmount < eurAmount ? pick(middle + 1, right) : pick(left, middle);
    };

    return customExchanges.length ? pick(0, customExchanges.length - 1) : {};
  });
