import { Conflict } from '../utils/types';

export const LC_CONFLICT_UPDATED = 'LC_CONFLICT/UPDATED';

export type LcConflictUpdatedAction = {
  type: typeof LC_CONFLICT_UPDATED;
  lcConflicts: Record<string, Conflict[]>;
};

export const updateLcConflicts = (lcConflicts: any): LcConflictUpdatedAction => ({
  type: LC_CONFLICT_UPDATED,
  lcConflicts,
});
