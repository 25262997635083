import { ApplicationAction } from '../actions';
import { LINES_GO_TO } from '../actions/goTo';
import { CLEAR_LINE } from '../actions/line';
import { SET_VISIBLE_FARE_CLASSES } from '../actions/visibleFareClasses';
import { FareClass } from '../utils/types';

export type VisibleFareClassesState = FareClass[];

const initialState: VisibleFareClassesState = [];

export default (state: VisibleFareClassesState = initialState, action: ApplicationAction): VisibleFareClassesState => {
  switch (action.type) {
    case SET_VISIBLE_FARE_CLASSES:
      return action.visibleFareClasses;
    case CLEAR_LINE:
      return [];
    case LINES_GO_TO:
      return [];

    default:
      return state;
  }
};
