export const SET_EDIT_OPTIONS = 'SET_EDIT_OPTIONS';

type EditOptionsData = {
  mirrorPrices?: boolean;
  autoConvert?: boolean;
};

export type EditOptionsAction = {
  type: typeof SET_EDIT_OPTIONS;
  editOptions: EditOptionsData;
};

export const setEditOptions = (editOptions: EditOptionsData): EditOptionsAction => ({
  type: SET_EDIT_OPTIONS,
  editOptions,
});
