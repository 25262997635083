import { Box, Button, Heading } from '@flixbus/honeycomb-react';
import React, { ReactNode } from 'react';

import { errorReport } from './datadog.utils';

import styles from './DatadogErrorBoundary.module.scss';

interface Props {
  children: ReactNode;
}

interface StateProps {
  hasError: boolean;
}

export default class DatadogErrorBoundary extends React.Component<Props, StateProps> {
  state = { hasError: false };

  public componentDidCatch(error: Error, data: React.ErrorInfo): void {
    errorReport(error, { data });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  private static onReload() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <Box>
            <Heading size={1}>Something went wrong.</Heading>
            <Heading size={3}>We have reported this error. Our engineers will be take a look at this soon.</Heading>
            <div className={styles.buttonContainer}>
              <Button appearance="tertiary" onClick={DatadogErrorBoundary.onReload}>
                Reload
              </Button>
            </div>
          </Box>
        </div>
      );
    }

    return this.props.children;
  }
}
