import { CurrentLineState } from '../reducers/currentLine';
import { EditOptionsState } from '../reducers/editOptions';
import { FilterState } from '../reducers/filter';
import { ForbiddenRelationsState } from '../reducers/forbiddenRelations';
import { RepositoryState } from '../reducers/repository';
import { StationsState } from '../reducers/stations';
import { VisibleCurrenciesState } from '../reducers/visibleCurrencies';
import { VisibleFareClassesState } from '../reducers/visibleFareClasses';
import { ApplicationState, CustomExchanges } from '../utils/types';

export const getVisibleCurrencies = (state: ApplicationState): VisibleCurrenciesState => state.visibleCurrencies;
export const repositorySelector = (state: ApplicationState): RepositoryState => state.repository;
export const pricesSelector = (state: ApplicationState): any => state.prices;
export const filterSelector = (state: ApplicationState): FilterState => state.filter;
export const editOptionsSelector = (state: ApplicationState): EditOptionsState => state.editOptions;
export const customExchangesSelector = (state: ApplicationState): CustomExchanges => state.customExchanges;
export const stationsSelector = (state: ApplicationState): StationsState => state.stations;
export const getVisibleFareClasses = (state: ApplicationState): VisibleFareClassesState => state.visibleFareClasses;
export const getForbiddenRelationsSelector = (state: ApplicationState): ForbiddenRelationsState =>
  state.forbiddenRelations;
export const currentLineSelector = (state: ApplicationState): CurrentLineState => state.currentLine;
