import { ApplicationAction } from '../actions';
import { SET_FILTER } from '../actions/filter';
import { CLEAR_LINE } from '../actions/line';

export type FilterState = {
  readonly type?: string;
  readonly subType?: number;
  readonly fromId?: string;
  readonly toId: string[];
};

const initialState: FilterState = { type: undefined, subType: undefined, fromId: undefined, toId: [] };

export default (state: FilterState = initialState, action: ApplicationAction): FilterState => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state, ...action.filter };
    case CLEAR_LINE:
      return { ...initialState };

    default:
      return state;
  }
};
