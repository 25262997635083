export const getNumber = (value: any, defaultValue: number | string = 0) => {
  const currentValue = parseFloat(value);

  if (isNaN(currentValue)) {
    return defaultValue;
  }

  return currentValue;
};

export const round = (value: number, precision = 0) => Number(value.toFixed(precision));

export const commasToPoints = (value: any) => String(value).replace(/,/g, '.');

const afterFrom = (currentDateTime: number, from?: string) => !from || Date.parse(from) <= currentDateTime;
const beforeTo = (currentDateTime: number, to?: string) => !to || currentDateTime <= Date.parse(to);

export const isDateTimeWithinDateRange = (currentDateTime: number, from?: string, till?: string) =>
  afterFrom(currentDateTime, from) && beforeTo(currentDateTime, till);

export const parseToIdsFromUrlString = (ids: string): string[] => ids.split(',').filter((id) => id.length);
