import { put, select, takeLatest } from 'redux-saga/effects';

import { handleSavingSuccess, PREPARE_SAVE, PrepareSaveAction, save, SaveAction } from '../actions/save';
import { FareTypeObjects, FareTypes } from '../constants/FareTypes';
import { getCurrentLineId } from '../selectors/lineSelector';
import { savePricesRequestSelector } from '../selectors/savePricesRequestSelector';
import { LineId, SavePayload } from '../utils/types';

const FareTypesAObject = FareTypeObjects[FareTypes.A];

export function* prepareSavePrices() {
  const lineId: LineId | null = yield select(getCurrentLineId);

  if (lineId === null) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const payload: any = yield select(savePricesRequestSelector);

  // If nothing to save, don't call
  if (payload.length === 0) {
    yield put<any>(handleSavingSuccess());

    return;
  }

  const hasEmptyAPrice = (payload as SavePayload).some((price) => {
    const isAClass = price.data.type === FareTypesAObject.type && price.data.subtype === FareTypesAObject.subType;
    const isPriceEmpty = Object.values(price.prices).some((p) => !p.price);
    const isEmptyAPrice = isAClass && isPriceEmpty;
    const isInactiveAPrice = isAClass && !price.active;

    return isEmptyAPrice || isInactiveAPrice;
  });

  if (hasEmptyAPrice) {
    alert('A-price cannot be empty or inactive');
  } else {
    yield put<SaveAction>(save(lineId, payload));
  }
}

export default function* prepareSavePricesSaga() {
  yield takeLatest<PrepareSaveAction>(PREPARE_SAVE, prepareSavePrices);
}
