import { ApplicationAction } from '../actions';
import { INITIAL_STATE_LOADED } from '../actions/initial';
import { CustomExchanges, PriceTypes } from '../utils/types';

export type InitialState = PriceTypes | CustomExchanges | null;

export default (key: 'custom_exchanges' | 'price_types') =>
  (state: InitialState = null, action: ApplicationAction): InitialState => {
    switch (action.type) {
      case INITIAL_STATE_LOADED:
        return action.payload[key];

      default:
        return state;
    }
  };
