import { LineDataPayload, LineId } from '../utils/types';

export const LOAD_LINE_BY_ID = 'LOAD_LINE_BY_ID';
export const LOAD_LINE_BY_ID_SUCCESS = 'LOAD_LINE_BY_ID_SUCCESS';
export const LOAD_LINE_BY_ID_FAIL = 'LOAD_LINE_BY_ID_FAIL';
export const CLEAR_LINE = 'CLEAR_LINE';

export type LoadLineByIdAction = {
  type: typeof LOAD_LINE_BY_ID;
  lineId: LineId;
};

export type LoadLineByIdSuccessAction = {
  type: typeof LOAD_LINE_BY_ID_SUCCESS;
  lineId: LineId;
  payload: LineDataPayload;
};

export type LoadLineByIdFailAction = {
  type: typeof LOAD_LINE_BY_ID_FAIL;
  lineId: LineId;
  payload: string;
};

export type ClearLineAction = {
  type: typeof CLEAR_LINE;
};

export const loadLineById = (lineId: LineId): LoadLineByIdAction => ({ type: LOAD_LINE_BY_ID, lineId });
export const loadLineSucceeded = (lineId: LineId, payload: LineDataPayload): LoadLineByIdSuccessAction => ({
  type: LOAD_LINE_BY_ID_SUCCESS,
  payload,
  lineId,
});
export const loadLineFailed = (lineId: LineId, payload: string): LoadLineByIdFailAction => ({
  type: LOAD_LINE_BY_ID_FAIL,
  payload,
  lineId,
});
export const clearLine = (): ClearLineAction => ({ type: CLEAR_LINE });
