import { push, RouterAction } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';

import { GO_TO_MAIN_PAGE, GoToMainPageAction } from '../actions/goTo';

export function* toMainPage() {
  yield put<RouterAction>(push('/'));
}

export default function* toMainPageSaga() {
  yield takeLatest<GoToMainPageAction>(GO_TO_MAIN_PAGE, toMainPage);
}
