// Fare types represent different possible prices for same relation.
// Technically it's a combination of price type and price subtype.

// Fare type codes
import { FareClass } from '../utils/types';

export const PriceTypes = {
  NORMAL: 'normal',
  GROUP: 'groups',
  CHILDREN: 'children',
  BIKE: 'bike_slot',
};

export const FareTypes = {
  A: 'normal.a',
  B: 'normal.b',
  C: 'normal.c',
  D: 'normal.d',
  E: 'normal.e',
  F: 'normal.f',
  G: 'normal.g',
  H: 'normal.h',
  PROMOTION: 'normal.promotional_action',
  CHILDREN: 'children',
};

export const FareTypeLabels = {
  [FareTypes.A]: 'A',
  [FareTypes.B]: 'B',
  [FareTypes.C]: 'C',
  [FareTypes.D]: 'D',
  [FareTypes.E]: 'E',
  [FareTypes.F]: 'F',
  [FareTypes.G]: 'G',
  [FareTypes.H]: 'H',
  [FareTypes.PROMOTION]: 'Promotional',
  [FareTypes.CHILDREN]: 'Children',
};

// Filter subsets related to each fare type
export const FareTypeObjects: { [key: string]: FareClass } = {
  [FareTypes.A]: {
    type: 'normal',
    subType: 1,
    key: FareTypes.A,
    label: FareTypeLabels[FareTypes.A],
  },
  [FareTypes.B]: {
    type: 'normal',
    subType: 2,
    key: FareTypes.B,
    label: FareTypeLabels[FareTypes.B],
  },
  [FareTypes.C]: {
    type: 'normal',
    subType: 3,
    key: FareTypes.C,
    label: FareTypeLabels[FareTypes.C],
  },
  [FareTypes.D]: {
    type: 'normal',
    subType: 5,
    key: FareTypes.D,
    label: FareTypeLabels[FareTypes.D],
  },
  [FareTypes.E]: {
    type: 'normal',
    subType: 4,
    key: FareTypes.E,
    label: FareTypeLabels[FareTypes.E],
  },
  [FareTypes.F]: {
    type: 'normal',
    subType: 8,
    key: FareTypes.F,
    label: FareTypeLabels[FareTypes.F],
  },
  [FareTypes.G]: {
    type: 'normal',
    subType: 6,
    key: FareTypes.G,
    label: FareTypeLabels[FareTypes.G],
  },
  [FareTypes.H]: {
    type: 'normal',
    subType: 9,
    key: FareTypes.H,
    label: FareTypeLabels[FareTypes.H],
  },
  [FareTypes.PROMOTION]: {
    type: 'normal',
    subType: 7,
    key: FareTypes.PROMOTION,
    label: FareTypeLabels[FareTypes.PROMOTION],
  },
  [FareTypes.CHILDREN]: {
    type: 'children',
    subType: 1,
    key: FareTypes.CHILDREN,
    label: FareTypeLabels[FareTypes.CHILDREN],
  },
};

export const FareTypeOrder = {
  [FareTypes.A]: 0,
  [FareTypes.B]: 1,
  [FareTypes.C]: 2,
  [FareTypes.D]: 3,
  [FareTypes.E]: 4,
  [FareTypes.F]: 5,
  [FareTypes.G]: 6,
  [FareTypes.H]: 7,
  [FareTypes.PROMOTION]: 8,
  [FareTypes.CHILDREN]: 9,
};

export enum FareConflictTypes {
  SOURCE = 'source',
  TARGET = 'target',
}
