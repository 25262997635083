import { useMsal } from '@azure/msal-react';
import { Box, Button } from '@flixbus/honeycomb-react';
import React from 'react';

import styles from './Logout.module.scss';

const LogoutComp: React.FC = () => {
  const { instance } = useMsal();
  const handleLogout = () =>
    instance.logoutRedirect({
      // Return false to stop navigation after local logout
      onRedirectNavigate: () => false,
    });

  return (
    <div className={styles.container}>
      <Box>
        <div className={styles.centerAlignedColumn}>
          <span className={styles.loginText}>
            Clicking Logout button will log you out of the Azure Active directory and possibly other applications you
            might be logged in
          </span>
          <Button appearance="primary" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Box>
    </div>
  );
};

export const Logout = React.memo(LogoutComp);
export default Logout;
