import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import EditOptions from '../EditOptions';
import ForbiddenRelations from '../ForbiddenRelations/ForbiddenRelations';

import MatrixView from './MatrixView';

import styles from './Matrix.module.scss';

class Matrix extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
  };

  render() {
    if (this.props.type === null) {
      // No prices yet
      return <div className="fare-classes">Please select filter</div>;
    }

    return (
      <div className="fare-classes">
        <div className="fare-class-item">
          <div className={styles.controls}>
            <EditOptions />
            <ForbiddenRelations />
          </div>
          <MatrixView />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  type: state.filter.type,
});

export default connect(mapStateToProps)(Matrix);
