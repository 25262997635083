import { DirectionRulesPayload, LineId } from '../utils/types';

export const SHOW_FORBIDDEN_RELATIONS = 'SHOW_FORBIDDEN_RELATIONS';
export const HIDE_FORBIDDEN_RELATIONS = 'HIDE_FORBIDDEN_RELATIONS';
export const LOAD_FORBIDDEN_RELATIONS = 'LOAD_FORBIDDEN_RELATIONS';
export const LOAD_FORBIDDEN_RELATIONS_SUCCESS = 'LOAD_FORBIDDEN_RELATIONS_SUCCESS';
export const LOAD_FORBIDDEN_RELATIONS_FAIL = 'LOAD_FORBIDDEN_RELATIONS_FAIL';

export type ShowForbiddenRelationsAction = {
  type: typeof SHOW_FORBIDDEN_RELATIONS;
  lineId: LineId;
};

export type HideForbiddenRelationsAction = {
  type: typeof HIDE_FORBIDDEN_RELATIONS;
  lineId: LineId;
};

export type LoadForbiddenRelationsAction = {
  type: typeof LOAD_FORBIDDEN_RELATIONS;
  lineId: LineId;
};

export type LoadForbiddenRelationsSuccessAction = {
  type: typeof LOAD_FORBIDDEN_RELATIONS_SUCCESS;
  lineId: LineId;
  payload: DirectionRulesPayload;
};

export type LoadForbiddenRelationsFailAction = {
  type: typeof LOAD_FORBIDDEN_RELATIONS_FAIL;
  lineId: LineId;
  payload: string;
};

export const showForbiddenRelations = (lineId: LineId): ShowForbiddenRelationsAction => ({
  type: SHOW_FORBIDDEN_RELATIONS,
  lineId,
});
export const hideForbiddenRelations = (lineId: LineId): HideForbiddenRelationsAction => ({
  type: HIDE_FORBIDDEN_RELATIONS,
  lineId,
});
export const loadLineDirectionRules = (lineId: LineId): LoadForbiddenRelationsAction => ({
  type: LOAD_FORBIDDEN_RELATIONS,
  lineId,
});
export const loadLineDirectionRulesSucceeded = (
  lineId: LineId,
  payload: DirectionRulesPayload
): LoadForbiddenRelationsSuccessAction => ({
  type: LOAD_FORBIDDEN_RELATIONS_SUCCESS,
  payload,
  lineId,
});
export const loadLineDirectionRulesFailed = (lineId: LineId, payload: string): LoadForbiddenRelationsFailAction => ({
  type: LOAD_FORBIDDEN_RELATIONS_FAIL,
  payload,
  lineId,
});
