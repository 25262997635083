import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LOAD_LINE_BY_ID,
  LoadLineByIdAction,
  LoadLineByIdFailAction,
  LoadLineByIdSuccessAction,
  loadLineFailed,
  loadLineSucceeded,
} from '../actions/line';
import { fetchLineData } from '../utils/api';
import { errorReport } from '../utils/datadog';
import { LineDataPayload } from '../utils/types';

export function* loadLine(action: LoadLineByIdAction) {
  const { lineId } = action;

  try {
    const lineData: LineDataPayload = yield call(fetchLineData, lineId);

    yield put<LoadLineByIdSuccessAction>(loadLineSucceeded(lineId, lineData));
  } catch (error) {
    errorReport(`Exception during loading line ${lineId} data`, { error });
    yield put<LoadLineByIdFailAction>(loadLineFailed(lineId, (error as Error).message));
  }
}

export default function* loadLineSaga() {
  yield takeLatest<LoadLineByIdAction>(LOAD_LINE_BY_ID, loadLine);
}
