export const SET_FILTER = 'SET_FILTER';

type FilterData = {
  type?: string;
  subType?: number;
  fromId?: string;
  toId?: string[];
};

export type SetFilterAction = {
  type: typeof SET_FILTER;
  filter: FilterData;
};

export const setFilter = (filter: FilterData): SetFilterAction => ({ type: SET_FILTER, filter });
