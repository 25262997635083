import { createSelector } from 'reselect';

import { LcConflictStateKey } from '../reducers/lcConflict.reducer';
import { createRelationKey } from '../utils/priceKey';
import { ApplicationState, RelationConflicts } from '../utils/types';

export const getLCConflictState = (state: ApplicationState): RelationConflicts => state[LcConflictStateKey];

const getFromStopIdFromProps = (_: ApplicationState, ownProps: Record<string, any>) => ownProps.fromStop.id;
const getToStopIdFromProps = (_: ApplicationState, ownProps: Record<string, any>) => ownProps.toStop.id;

export const getRelationConflicts = () =>
  createSelector([getFromStopIdFromProps, getToStopIdFromProps, getLCConflictState], (fromId, toId, lcConflicts) => {
    const key = createRelationKey(fromId, toId);

    return lcConflicts[key] || [];
  });
