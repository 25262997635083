import { put, select, takeLatest } from 'redux-saga/effects';

import { updateLcConflicts } from '../actions/lcConflict.actions';
import { PriceActionTypes } from '../constants/ActionTypes';
import {
  getRelationNormalPriceFromKey,
  getRelationNormalPrices,
  RelationPrices,
} from '../selectors/preparedPricesSelector';
import { getLcConflictsInPricesArray } from '../utils/lcConflict.utils';
import { createRelationKey, splitKey } from '../utils/priceKey';
import { Price, RelationConflicts } from '../utils/types';

function* pricesLoadedSaga() {
  const relationPrices: RelationPrices = yield select(getRelationNormalPrices);
  // finding any conflicts on relation level. will be an expensive calculation run once per line loaded
  // When LC/CC state changes, calculate conflicts in the changing relation only handled in separate saga.
  const lcConflicts = Object.entries(relationPrices).reduce<RelationConflicts>((acc, [key, prices]) => {
    const conflicts = getLcConflictsInPricesArray(prices);

    if (conflicts.length > 0) {
      acc[key] = getLcConflictsInPricesArray(prices);
    }

    return acc;
  }, {});

  yield put(updateLcConflicts(lcConflicts));
}

type PriceUpdateAction = {
  type: typeof PriceActionTypes.UPDATE_PRICE;
  key: string;
};

function* pricesUpdateSaga(action: PriceUpdateAction) {
  const keyParts = splitKey(action.key);
  const relationKey = createRelationKey(keyParts.fromId, keyParts.toId);
  const relationPrices: Price[] = yield select((state) => getRelationNormalPriceFromKey(state, keyParts));
  const relationConflicts = getLcConflictsInPricesArray(relationPrices);
  const lcConflicts = {
    [relationKey]: relationConflicts,
  };

  yield put(updateLcConflicts(lcConflicts));
}

export default function* lcConflictSaga() {
  yield takeLatest(PriceActionTypes.SET_FILTERED_PRICES, pricesLoadedSaga);
  // triggered inside handlePriceUpdateSaga after CC/LC value/active state changes
  yield takeLatest(PriceActionTypes.UPDATE_PRICE_COMPLETE, pricesUpdateSaga);
}
