import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import currencies from './currencies';
import currentLine from './currentLine';
import editOptions from './editOptions';
import filter from './filter';
import forbiddenRelations from './forbiddenRelations';
import initial from './initial';
import initialized from './initialized';
import isEditing from './isEditing';
import isSaving from './isSaving';
import { default as lcConflictReducer, LcConflictStateKey } from './lcConflict.reducer';
import lines from './lines';
import { prices } from './prices';
import repository from './repository';
import stations from './stations';
import visibleCurrencies from './visibleCurrencies';
import visibleFareClasses from './visibleFareClasses';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    currencies,
    priceTypes: initial('price_types'),
    repository,
    prices,
    stations,
    filter,
    isEditing,
    isSaving,
    visibleCurrencies,
    editOptions,
    customExchanges: initial('custom_exchanges'),
    initialized,
    lines,
    currentLine,
    visibleFareClasses,
    forbiddenRelations,
    [LcConflictStateKey]: lcConflictReducer,
  });
