import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { hideForbiddenRelations, showForbiddenRelations } from '../../actions/forbiddenRelations';
import {
  areForbiddenRelationsLoaded,
  areForbiddenRelationsLoading,
  areForbiddenRelationsVisible,
  getForbiddenRelationsError,
} from '../../selectors/forbiddenRelationsSelector';
import { getCurrentLineId } from '../../selectors/lineSelector';
import { trans } from '../../utils/Translator';

import styles from './ForbiddenRelations.module.scss';

class ForbiddenRelations extends React.PureComponent {
  static propTypes = {
    hideForbiddenRelations: PropTypes.func.isRequired,
    showForbiddenRelations: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    currentLineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    visible: PropTypes.bool.isRequired,
  };

  handleChange = (event) =>
    event.target.checked
      ? this.props.hideForbiddenRelations(this.props.currentLineId)
      : this.props.showForbiddenRelations(this.props.currentLineId);

  render() {
    const { visible, isLoading, error } = this.props;

    return (
      <div className={styles.inline}>
        <span className={styles.directionRules}>
          <label>
            <input type="checkbox" checked={!visible} onChange={this.handleChange} />
            {trans('Hide forbidden relations')}
          </label>
        </span>
        {isLoading ? <FontAwesomeIcon icon={faSpinner} size="1x" /> : null}
        {error !== null ? <span className={styles.error}>{error}</span> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoaded: areForbiddenRelationsLoaded(state),
  currentLineId: getCurrentLineId(state),
  isLoading: areForbiddenRelationsLoading(state),
  error: getForbiddenRelationsError(state),
  visible: areForbiddenRelationsVisible(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideForbiddenRelations,
      showForbiddenRelations,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForbiddenRelations);
