import { LineId } from '../utils/types';

export const GO_TO_MAIN_PAGE = 'GO_TO_MAIN_PAGE';
export const LINES_GO_TO = 'LINES_GO_TO';

export type GoToLineAction = {
  type: typeof LINES_GO_TO;
  lineId: LineId;
};

export type GoToMainPageAction = {
  type: typeof GO_TO_MAIN_PAGE;
};

export const goToLine = (lineId: LineId): GoToLineAction => ({ type: LINES_GO_TO, lineId });
export const goToMainPage = (): GoToMainPageAction => ({ type: GO_TO_MAIN_PAGE });
