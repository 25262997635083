import { ApplicationAction } from '../actions';
import { SAVE, SAVING_ERROR, SAVING_SUCCESS } from '../actions/save';

export type SavingState = boolean;

export default (state: SavingState = false, action: ApplicationAction): SavingState => {
  switch (action.type) {
    case SAVE:
      return true;
    case SAVING_SUCCESS:
      return false;
    case SAVING_ERROR:
      return false;

    default:
      return state;
  }
};
