import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';

import { stopOption } from '../../../utils/propTypes';
import Station from '../Station/Station';

import styles from './StationOption.module.scss';

class StationOption extends React.PureComponent {
  render() {
    const { label, data, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } =
      this.props;

    return (
      <div
        ref={innerRef}
        className={cx(
          css(getStyles('option', this.props)),
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className
        )}
        {...innerProps}
      >
        <Station code={data.code} label={label} codeCustomStyle={styles.code} />
      </div>
    );
  }
}

StationOption.propTypes = {
  label: PropTypes.string,
  data: stopOption,
  cx: PropTypes.func,
  getStyles: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
};

export default StationOption;
