import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Station.module.scss';

const Station = ({ label, code, codeCustomStyle = '' }) => (
  <span className={styles.station}>
    <span className={classNames(styles.code, codeCustomStyle)}>{code}</span>
    <span className={styles.name}>{label}</span>
  </span>
);

Station.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  codeCustomStyle: PropTypes.string.isRequired,
};

export default Station;
