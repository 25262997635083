import { put, select, takeLatest } from 'redux-saga/effects';

import { CANCEL_EDITING, CancelEditingAction } from '../actions/edit';
import { LOAD_LINE_BY_ID_SUCCESS, LoadLineByIdAction } from '../actions/line';
import { setFilteredPrices } from '../actions/priceActions';
import { SAVING_SUCCESS, SavingSuccessAction } from '../actions/save';
import { getPreparedPrices } from '../selectors/preparedPricesSelector';
import { PreliminaryPricesStructure } from '../utils/types';

export function* resetPrices() {
  const filteredPrices: PreliminaryPricesStructure = yield select(getPreparedPrices);

  yield put<any>(setFilteredPrices(filteredPrices));
}

export default function* resetPricesSaga() {
  yield takeLatest<LoadLineByIdAction>(LOAD_LINE_BY_ID_SUCCESS, resetPrices);
  yield takeLatest<CancelEditingAction>(CANCEL_EDITING, resetPrices);
  yield takeLatest<SavingSuccessAction>(SAVING_SUCCESS, resetPrices);
}
