import { LineId, Price, SavePayload } from '../utils/types';

export const PREPARE_SAVE = 'PREPARE_SAVE';
export const SAVE = 'SAVE';
export const SAVING_SUCCESS = 'SAVING_SUCCESS';
export const SAVING_ERROR = 'SAVING_ERROR';

export type SaveAction = {
  type: typeof SAVE;
  lineId: LineId;
  payload: SavePayload;
};

export type PrepareSaveAction = {
  type: typeof PREPARE_SAVE;
};

export type SavingSuccessAction = {
  type: typeof SAVING_SUCCESS;
  updatedPrices: Price[];
  deletedPriceKeys: string[];
};

export type SavingErrorAction = {
  type: typeof SAVING_ERROR;
};

export const prepareSave = (): PrepareSaveAction => ({ type: PREPARE_SAVE });
export const save = (lineId: LineId, payload: SavePayload): SaveAction => ({ type: SAVE, lineId, payload });
export const handleSavingSuccess = (
  updatedPrices: Price[] = [],
  deletedPriceKeys: string[] = []
): SavingSuccessAction => ({
  type: SAVING_SUCCESS,
  updatedPrices,
  deletedPriceKeys,
});
export const handleSavingError = (): SavingErrorAction => ({ type: SAVING_ERROR });
