import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrentSelectedFare } from '../../selectors/currentFareCodeSelector';
import { areForbiddenRelationsVisible, getForbiddenRelations } from '../../selectors/forbiddenRelationsSelector';
import { fareClassShape, stopShape } from '../../utils/propTypes';
import { trans } from '../../utils/Translator';
import FareSelector from '../FareSelector';
import TableCell from '../TableCell';

import styles from './Matrix.module.scss';

const OldTable = (props) => {
  const { stations, fareClass, forbiddenRelations, visible } = props;

  return (
    <div>
      <FareSelector />
      <table className="matrix-full-view">
        <thead className="sticky-y">
          <tr className="header table-header">
            <th key="empty" className="table-row__title table-row__station-name" />
            {stations.map((stop) => (
              <th
                key={`y-${stop.id}`}
                className="title table-header__title table-header__station-name"
                title={stop.code}
              >
                {stop.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stations.map((fromStop) => (
            <tr key={`row-${fromStop.id}`} className="table-row">
              <th key={`x-${fromStop.id}`} title={fromStop.code} className="table-row__title table-row__station-name">
                {fromStop.name}
              </th>
              {stations.map((toStop) =>
                !(!visible && forbiddenRelations[fromStop.id] && forbiddenRelations[fromStop.id][toStop.id]) ? (
                  <TableCell
                    key={`${fromStop.id}-${toStop.id}`}
                    fromStop={fromStop}
                    toStop={toStop}
                    fareClass={fareClass}
                  />
                ) : (
                  <td className={styles.forbidden} key={`del-${fromStop.id}-${toStop.id}`}>
                    {trans('Forbidden')}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

OldTable.propTypes = {
  stations: PropTypes.arrayOf(stopShape).isRequired,
  fareClass: fareClassShape.isRequired,
  visible: PropTypes.bool.isRequired,
  forbiddenRelations: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stations: state.stations,
  fareClass: getCurrentSelectedFare(state),
  forbiddenRelations: getForbiddenRelations(state),
  visible: areForbiddenRelationsVisible(state),
});

export default connect(mapStateToProps)(OldTable);
