import PropTypes from 'prop-types';
import React from 'react';

import { CC_MAX, CC_MIN, CC_PATTERN } from '../../constants/parameters';

export class ContingentClassInput extends React.Component {
  static propTypes = {
    inputClassName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  };

  onChange = (event) => {
    const input = event.target;
    const value = String(input.value);

    if (input.validity.badInput || !CC_PATTERN.test(value)) {
      return;
    }

    const amount = parseInt(value, 10);

    if (isNaN(amount)) {
      this.props.onChange('');

      return;
    }

    if (amount > CC_MAX) {
      return;
    }

    this.props.onChange(amount);
  };

  render() {
    const { inputClassName, value } = this.props;

    return (
      <input
        className={inputClassName}
        type="number"
        min={CC_MIN}
        max={CC_MAX}
        step={1}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}
