import { trans } from '../utils/Translator';

import PageLayout from './PageLayout';

const IndexPage = () => (
  <PageLayout>
    <h3>{trans('Please select a line.')}</h3>
  </PageLayout>
);

export default IndexPage;
