import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';

import { lineObjectShape } from '../../../utils/propTypes';
import Line from '../Line/Line';

export default class Option extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    data: lineObjectShape,
    cx: PropTypes.func,
    getStyles: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
  };

  render() {
    const { label, data, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } =
      this.props;

    return (
      <div
        ref={innerRef}
        className={cx(
          css(getStyles('option', this.props)),
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className
        )}
        {...innerProps}
      >
        <Line
          backgroundColor={data.attributes.bg_color}
          color={data.attributes.fg_color}
          code={data.attributes.code}
          label={label}
        />
      </div>
    );
  }
}
