import PropTypes from 'prop-types';
import React from 'react';

import { BASE_CURRENCY } from '../../constants/parameters';

import styles from './Yielding.module.scss';

export class Yielding extends React.PureComponent {
  static propTypes = {
    prices: PropTypes.object.isRequired,
    distance: PropTypes.number.isRequired,
  };
  render() {
    const { prices, distance } = this.props;

    const yielding = BASE_CURRENCY in prices && distance > 0 ? (prices[BASE_CURRENCY].value / distance) * 100 : 0;

    if (!(yielding > 0.0001)) {
      return null;
    }

    return <sup className={styles.yield}>{yielding.toFixed(2)}</sup>;
  }
}

export default Yielding;
