import { put, select, takeEvery } from 'redux-saga/effects';

import { mirroredPriceAction, setBatchPrices } from '../actions/priceActions';
import { PriceActionTypes } from '../constants/ActionTypes';
import { BASE_CURRENCY } from '../constants/parameters';
import { PriceUpdateTypes } from '../constants/PriceUpdateTypes';
import { createExchangeRatesSelector } from '../selectors/createExchangeRatesSelector';
import { editOptionsSelector } from '../selectors/stateSelectors';
import { mirroredKey } from '../utils/priceKey';

const lcConflictUpdateTypes = [
  PriceUpdateTypes.SET_LC,
  PriceUpdateTypes.SET_CC,
  PriceUpdateTypes.SET_ACTIVE,
  PriceUpdateTypes.SET_LINE_DEACTIVE,
];

export function* handlePriceUpdate(action) {
  if (action.isMirrored) {
    return; // Action was caused by side effect and should have no own side effects
  }

  const editOptions = yield select(editOptionsSelector);

  if (editOptions.mirrorPrices) {
    yield put(mirroredPriceAction(action));
  }

  const shouldConvertPrices =
    editOptions.autoConvert && action.updateType === PriceUpdateTypes.SET_VALUE && action.currency === BASE_CURRENCY;

  if (shouldConvertPrices) {
    const exchangeRates = yield select(createExchangeRatesSelector(action.value));
    const pricesToUpdate = [];

    // eslint-disable-next-line no-unused-vars
    for (const currency in exchangeRates) {
      if (currency !== BASE_CURRENCY) {
        pricesToUpdate.push({
          key: action.key,
          currency,
          value: exchangeRates[currency],
        });
        if (editOptions.mirrorPrices) {
          pricesToUpdate.push({
            key: mirroredKey(action.key),
            currency,
            value: exchangeRates[currency],
          });
        }
      }
    }
    yield put(setBatchPrices(pricesToUpdate));
  }

  // LCConflictSaga listens to this action to recalculate conflicts on LC/CC Changes
  if (lcConflictUpdateTypes.includes(action.updateType)) {
    yield put({
      type: PriceActionTypes.UPDATE_PRICE_COMPLETE,
      key: action.key,
    });
  }
}

export default function* handlePriceUpdateSaga() {
  yield takeEvery(PriceActionTypes.UPDATE_PRICE, handlePriceUpdate);
}
