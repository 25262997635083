import { takeEvery } from 'redux-saga/effects';

import { ApplicationAction } from '../actions';
import { INITIAL_STATE_LOADED } from '../actions/initial';
import { LOAD_LINE_BY_ID_SUCCESS } from '../actions/line';
import { LINES_LOADED } from '../actions/lines';
import { SAVE, SAVING_SUCCESS } from '../actions/save';
import { PriceActionTypes } from '../constants/ActionTypes';

// Payload for these actions is big and useless
const actionsToBeTrimmed = [
  INITIAL_STATE_LOADED,
  LINES_LOADED,
  LOAD_LINE_BY_ID_SUCCESS,
  PriceActionTypes.SET_FILTERED_PRICES,
  PriceActionTypes.BATCH_UPDATE,
  SAVE,
  SAVING_SUCCESS,
];

export function logAction(action: ApplicationAction) {
  const { type, ...payload } = action;

  // eslint-disable-next-line no-console
  console.debug('Redux', type, actionsToBeTrimmed.includes(type) ? '(payload is trimmed)' : payload);
}

/**
 * Redux saga that logs actions to console so Rollbar Telemetry can add them to report if error happens.
 */
export default function* telemetrySaga() {
  yield takeEvery<ApplicationAction>('*', logAction);
}
