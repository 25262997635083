import { createSelector } from 'reselect';

import { PriceTypes } from '../constants/FareTypes';
import { isNormalPrice } from '../utils/price.utils';
import priceKey, { createRelationKey } from '../utils/priceKey';
import { ApplicationState, PreliminaryPricesStructure, Price } from '../utils/types';

import { pricesSelector, repositorySelector } from './stateSelectors';

const getFromStopIdFromProps = (_: ApplicationState, ownProps: Record<string, any>): string => ownProps.fromId;
const getToStopIdFromProps = (_: ApplicationState, ownProps: Record<string, any>): string => ownProps.toId;

export const getPreparedPrices = createSelector<ApplicationState, Price[], PreliminaryPricesStructure>(
  [repositorySelector],
  (repository) =>
    repository.reduce((carry: PreliminaryPricesStructure, price) => {
      const lineClass = price.lineClass || { class: '', active: true };

      carry[priceKey(price)] = {
        ...price,
        lineClass,
      };

      return carry;
    }, {})
);

export type RelationPrices = {
  [key: string]: Price[];
};
export const getRelationNormalPrices = createSelector<ApplicationState, Price[], RelationPrices>(
  [repositorySelector],
  (repository) =>
    repository.reduce<RelationPrices>((carry, price) => {
      const lineClass = price.lineClass || { class: '', active: true };
      const relationKey = createRelationKey(price.fromId, price.toId);
      const relationPrices = Array.isArray(carry[relationKey]) ? carry[relationKey] : [];

      if (isNormalPrice(price)) {
        carry[relationKey] = [...relationPrices, { ...price, lineClass }];
      }

      return carry;
    }, {})
);

export const getRelationNormalPriceFromKey = createSelector<ApplicationState, any, string, string, Price[], Price[]>(
  [getFromStopIdFromProps, getToStopIdFromProps, pricesSelector],
  (fromId, toId, prices) => {
    const key = `${fromId}:${toId}:${PriceTypes.NORMAL}`;

    return Object.entries(prices).reduce<Price[]>((acc, [k, price]) => {
      if (k.includes(key)) {
        acc.push(price);
      }

      return acc;
    }, []);
  }
);
