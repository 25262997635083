import { fork } from 'redux-saga/effects';

import handlePriceUpdateSaga from './handlePriceUpdate';
import initializeSaga from './initialize';
import lcConflictSaga from './lcConflict.saga';
import loadForbiddenRelationsSaga from './loadForbiddenRelations';
import loadLineSaga from './loadLine';
import prepareSavePricesSaga from './prepareSavePricesSaga';
import resetPricesSaga from './resetPrices';
import savePricesSaga from './savePricesSaga';
import selectLineSaga from './selectLine';
import telemetrySaga from './telemetry';
import toMainPageSaga from './toMainPage';

function* rootSaga() {
  yield fork(telemetrySaga);
  yield fork(resetPricesSaga);
  yield fork(prepareSavePricesSaga);
  yield fork(savePricesSaga);
  yield fork(handlePriceUpdateSaga);
  yield fork(initializeSaga);
  yield fork(selectLineSaga);
  yield fork(loadLineSaga);
  yield fork(toMainPageSaga);
  yield fork(loadForbiddenRelationsSaga);
  yield fork(lcConflictSaga);
}

export default rootSaga;
