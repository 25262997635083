import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { initApplication } from '../actions/initial';
import IndexPage from '../pages/IndexPage';
import MatrixContainer from '../pages/MatrixContainer';
import NotFoundException from '../pages/NotFoundException';

class ProtectedArea extends React.PureComponent {
  componentDidMount() {
    if (!this.props.initialized.initialized) {
      this.props.initApplication();
    }
  }

  render() {
    const {
      initialized: { initialized, error },
    } = this.props;

    if (!initialized) {
      if (error) {
        return <div className="fare-classes">Error occurred while initializing: {error}</div>;
      }

      return <div className="fare-classes">Initializing...</div>;
    }

    return (
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/line/:lineId(\d+)/from/:fromId(\d+)/to/:toId" component={MatrixContainer} />
        <Route exact path="/line/:lineId(\d+)/from/:fromId(\d+)" component={MatrixContainer} />
        <Route exact path="/line/:lineId(\d+)" component={MatrixContainer} />
        <Route component={NotFoundException} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  initialized: state.initialized,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ initApplication }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedArea);
