import { ApplicationAction } from '../actions';
import { CANCEL_EDITING, START_EDITING } from '../actions/edit';
import { LINES_GO_TO } from '../actions/goTo';
import { SAVE, SAVING_ERROR, SAVING_SUCCESS } from '../actions/save';

export type EditingState = boolean;

export default (state: EditingState = false, action: ApplicationAction): EditingState => {
  switch (action.type) {
    case SAVE:
      return false;
    case SAVING_SUCCESS:
      return false;
    case SAVING_ERROR:
      return true;
    case START_EDITING:
      return true;
    case CANCEL_EDITING:
      return false;
    case LINES_GO_TO:
      return false;

    default:
      return state;
  }
};
