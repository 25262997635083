import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import allSagas from '../sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['visibleCurrencies'],
};

const configureStore = (history, initialState = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));

  const reducer = rootReducer(history);
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);

  sagaMiddleware.run(allSagas);

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');

      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
};

export default configureStore;
