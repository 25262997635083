import PropTypes from 'prop-types';
import React from 'react';

import { trans } from '../../utils/Translator';

import styles from './Actiovation.module.scss';

export class Activation extends React.PureComponent {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChange = (event) => {
    this.props.onChange(event.target.checked);
  };

  render() {
    const { isEditing, active } = this.props;

    if (!isEditing) {
      return null;
    }

    return (
      <label className={styles.active} title={trans('Uncheck to disable price')}>
        <input type="checkbox" checked={active} className={styles.input} onChange={this.onChange} />
        {trans('Active')}
      </label>
    );
  }
}

export default Activation;
