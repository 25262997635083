export const menu = {
  zIndex: 20,
  maxHeight: 300,
};

export const container = {
  flexGrow: 1,
  flexBasis: 300,
};

export const multiValueLabel = {
  paddingRight: 6,
  color: '#73d700',
  background: '#fff',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#73d700',
  borderLeft: 0,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
};

export const multiValueRemove = {
  color: '#73d700',
  background: '#fff',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#73d700',
  cursor: 'pointer',
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  ':hover': {
    background: '#80d600',
    borderColor: '#80d600',
    color: '#fff',
  },
};

export const clearIndicator = {
  cursor: 'pointer',
};
