import { createSelector } from 'reselect';

import { FareTypeObjects, FareTypes } from '../constants/FareTypes';
import { FilterState } from '../reducers/filter';
import { ApplicationState, FareClass } from '../utils/types';

import { filterSelector } from './stateSelectors';

export const currentFareCodeSelector = createSelector<ApplicationState, FilterState, string>(
  [filterSelector],
  ({ type, subType }) => {
    const currentFareClass = Object.entries(FareTypeObjects).find(
      ([_code, fare]) => fare.type === type && fare.subType === subType
    );

    if (currentFareClass === undefined) {
      return FareTypes.A;
    }

    return currentFareClass[0];
  }
);

export const getCurrentSelectedFare = createSelector<ApplicationState, string, FareClass>(
  [currentFareCodeSelector],
  (fareType) => FareTypeObjects[fareType]
);
