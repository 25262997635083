import { ApplicationAction } from '../actions';
import { LC_CONFLICT_UPDATED } from '../actions/lcConflict.actions';
import { RelationConflicts } from '../utils/types';

export const LcConflictStateKey = 'lcConflict';
export type LcConflictState = RelationConflicts;

const initialState: LcConflictState = {};

export default (state: LcConflictState = initialState, action: ApplicationAction): LcConflictState => {
  switch (action.type) {
    case LC_CONFLICT_UPDATED:
      return {
        ...state,
        ...action.lcConflicts,
      };

    default:
      return state;
  }
};
