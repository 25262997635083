import { all, call, put, take } from 'redux-saga/effects';

import {
  INIT_APPLICATION,
  InitAction,
  initializationFailed,
  InitializationFailedAction,
  initializationSucceeded,
  InitializedAction,
} from '../actions/initial';
import { LinesFailedAction, LinesLoadedAction, loadLinesFailed, loadLinesSucceeded } from '../actions/lines';
import { fetchInitializationData, fetchLinesData } from '../utils/api';
import { errorReport } from '../utils/datadog';
import { InitialPayload, LinesPayload } from '../utils/types';

export function* initialize() {
  try {
    const [initialData, linesData]: [InitialPayload, LinesPayload] = yield all([
      call(fetchInitializationData),
      call(fetchLinesData),
    ]);

    yield all([
      put<InitializedAction>(initializationSucceeded(initialData)),
      put<LinesLoadedAction>(loadLinesSucceeded(linesData)),
    ]);
  } catch (error) {
    errorReport('Exception during initialization request', {
      error,
    });
    yield put<InitializationFailedAction>(initializationFailed((error as Error).message));
    yield put<LinesFailedAction>(loadLinesFailed((error as Error).message));
  }
}

export default function* initializeSaga() {
  yield take<InitAction>(INIT_APPLICATION);
  yield call(initialize);
}
