import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setFilter } from '../actions/filter';
import { FareTypeLabels, FareTypeObjects } from '../constants/FareTypes';
import { currentFareCodeSelector } from '../selectors/currentFareCodeSelector';

import styles from './FareSelector.module.scss';

class FareSelector extends React.PureComponent {
  onSelectFareCallbacks = Object.keys(FareTypeLabels).reduce(
    (acc, code) => ({
      ...acc,
      [code]: () => this.props.setFilter({ type: FareTypeObjects[code].type, subType: FareTypeObjects[code].subType }),
    }),
    {}
  );

  render() {
    const { currentFareCode } = this.props;

    return (
      <nav className={classNames(styles.selector, 'flix-nav-horizontal')}>
        <ul className="flix-nav-horizontal__items">
          {Object.entries(FareTypeLabels).map(([code, label]) => {
            const linkClassName = classNames('flix-nav-horizontal__link', {
              'flix-nav-horizontal__link--active': code === currentFareCode,
            });

            return (
              <li key={code} onClick={this.onSelectFareCallbacks[code]} className="flix-nav-horizontal__item">
                <span className={linkClassName}>{label}</span>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

FareSelector.propTypes = {
  currentFareCode: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentFareCode: currentFareCodeSelector(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setFilter }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FareSelector);
