import { cloneDeep, merge } from 'lodash';

import { acquireAccessToken } from '../features/auth';

export const defaultOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'x-requested-with': 'XMLHttpRequest',
    'content-type': 'application/json',
    accept: 'application/json',
  },
};

const doFetch = async (path: string, options: RequestInit) => {
  const authToken = await acquireAccessToken();
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const url =
    process.env.NODE_ENV !== 'test' && process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}${path}` : path;

  return fetch(url, merge({}, cloneDeep(defaultOptions), options, { headers }));
};

export const apiRequest = async <ApiResponse>(path: string, options: RequestInit): Promise<ApiResponse> => {
  const response = await doFetch(path, options);

  if (!response.ok) {
    let message;

    try {
      message = (await response.clone().json()).message;
    } catch (error) {
      message = await response.clone().text();
    }

    throw new Error(message || response.statusText);
  }

  return response.json() as Promise<ApiResponse>;
};

export const get = async <ApiResponse = any>(path: string, options: RequestInit = {}): Promise<ApiResponse> =>
  apiRequest<ApiResponse>(path, { method: 'GET', ...options });

export const post = async <ApiResponse = any, ApiPayload = any>(
  path: string,
  data: ApiPayload,
  options: RequestInit = {}
) =>
  apiRequest<ApiResponse>(path, {
    method: 'POST',
    body: JSON.stringify(data),
    ...options,
  });
