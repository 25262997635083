import { PriceActionTypes } from '../constants/ActionTypes';
import { createReducer } from '../utils/reducerHelper';

import price from './price';

export const prices = createReducer(
  {},
  {
    [PriceActionTypes.SET_FILTERED_PRICES]: (state, action) => action.prices,
    [PriceActionTypes.UPDATE_PRICE]: (state, action) => ({
      ...state,
      [action.key]: price(state[action.key], action),
    }),
    [PriceActionTypes.BATCH_UPDATE]: (state, action) =>
      action.prices.reduce(
        (carry, item) => ({
          ...carry,
          [item.key]: price(carry[item.key], {
            ...item,
            updateType: action.updateType,
          }),
        }),
        { ...state }
      ),
  }
);
