import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { trans } from '../../utils/Translator';
import { ContingentClassInput } from '../common/ContingentClassInput';

import styles from './LineClass.module.scss';

export class LineClass extends React.PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isUnsaved: PropTypes.bool.isRequired,
  };

  render() {
    const { value, isEditing = false, isUnsaved = false, onChange } = this.props;

    if (!value && !isEditing) {
      return null;
    }

    const inputClassName = classNames(styles.value, styles.input, {
      [styles.unsaved]: isUnsaved,
    });

    return (
      <div className={styles.class} title={trans('Class for this line')}>
        <span className={styles.label}>{trans('LC')}</span>
        {isEditing && <ContingentClassInput inputClassName={inputClassName} onChange={onChange} value={value} />}
        {!isEditing && <span className={styles.value}>{value}</span>}
      </div>
    );
  }
}

export default LineClass;
