import { ApplicationAction } from '../actions';
import { CLEAR_LINE, LOAD_LINE_BY_ID_SUCCESS } from '../actions/line';
import { Station } from '../utils/types';

export type StationsState = Station[];

const initialState: StationsState = [];

export default (state: StationsState = initialState, action: ApplicationAction): StationsState => {
  switch (action.type) {
    case LOAD_LINE_BY_ID_SUCCESS:
      return action.payload.stations;
    case CLEAR_LINE:
      return [];

    default:
      return state;
  }
};
