import React, { ReactNode } from 'react';

import ControlPanel from '../components/ControlPanel';
import InitializationError from '../components/InitializationError';

import styles from './App.module.scss';

type Props = {
  children?: ReactNode;
};

export default class PageLayout extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.body}>
        <div className={styles.content}>
          <ControlPanel />
          <div>{this.props.children}</div>
          <InitializationError />
        </div>
      </div>
    );
  }
}
