import { createSelector } from 'reselect';

import { FareTypeLabels, FareTypeObjects, FareTypeOrder } from '../constants/FareTypes';
import { VisibleFareClassesState } from '../reducers/visibleFareClasses';
import { ApplicationState, FareClass, SelectOptionItem } from '../utils/types';

import { getVisibleFareClasses } from './stateSelectors';

export const getVisibleFareClassesObjects = createSelector<ApplicationState, VisibleFareClassesState, FareClass[]>(
  [getVisibleFareClasses],
  (visibleFareClasses) => (visibleFareClasses.length === 0 ? Object.values(FareTypeObjects) : visibleFareClasses)
);

type FareClassValues = SelectOptionItem[];

export const getVisibleFareClassesFilterValues = createSelector<
  ApplicationState,
  VisibleFareClassesState,
  FareClassValues
>([getVisibleFareClasses], (visibleFareClasses) =>
  visibleFareClasses
    .sort((a, b) => FareTypeOrder[a.key] - FareTypeOrder[b.key])
    .map((fareClass) => ({ label: FareTypeLabels[fareClass.key], value: fareClass.key }))
);
