import React from 'react';

import styles from './Line.module.scss';

type LineProps = {
  label: string;
  code: string;
  backgroundColor: string;
  color: string;
};

export default class Line extends React.PureComponent<LineProps> {
  public render() {
    const { backgroundColor, color, label, code } = this.props;

    return (
      <span className={styles.line}>
        <span className={styles.code} style={{ backgroundColor, color }}>
          {code}
        </span>
        <span className={styles.name}>{label}</span>
      </span>
    );
  }
}
