import PropTypes from 'prop-types';
import React from 'react';

import { stopOption } from '../../../utils/propTypes';
import Station from '../Station/Station';

import styles from './StationMultiValueLabel.module.scss';

export default class StationMultiValueLabel extends React.PureComponent {
  static propTypes = {
    data: stopOption,
    innerProps: PropTypes.object,
  };

  render() {
    const { data, innerProps } = this.props;

    return (
      <div {...innerProps}>
        <Station code={data.code} label={data.label} codeCustomStyle={styles.code} />
      </div>
    );
  }
}
