import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ApplicationState, Line, LineDataFromURL, LineId } from '../utils/types';

const getLines = (state: ApplicationState): Line[] => state.lines.list;

type Params = {
  lineId: string;
  fromId: string;
  toId: string;
};

const getParams = (_state: ApplicationState, ownProps: any): Params => get(ownProps, 'match.params', {});

export const getLineDataFromTheURL = createSelector<ApplicationState, any, Params, LineDataFromURL>(
  [getParams],
  (params) => ({
    lineId: params.lineId,
    fromId: params.fromId,
    toId: params.toId,
  })
);

type LineIdOrNull = LineId | null;

export const getCurrentLineId = (state: ApplicationState): LineIdOrNull => state.currentLine.lineId;

type LineOrNull = Line | null;

export const getLineById = createSelector<ApplicationState, Line[], LineIdOrNull, LineOrNull>(
  [getLines, getCurrentLineId],
  (lines, lineId) => {
    if (lineId === null) {
      return null;
    }

    return lines.find((line) => String(line.value) === lineId) || null;
  }
);
