import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const DeleteButton = ({ price, onDelete, onUndelete }) => {
  if (!price.active && !price.isDeleted) {
    return null;
  }

  const onClick = !price.isDeleted ? onDelete : onUndelete;

  const spanClass = classNames('price-action-delete', {
    'price-action-delete--deleted': price.isDeleted,
  });

  return (
    <span className={spanClass} onClick={onClick}>
      <FontAwesomeIcon icon={faTrashAlt} />
    </span>
  );
};

DeleteButton.propTypes = {
  // key: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUndelete: PropTypes.func.isRequired,
};

export default DeleteButton;
