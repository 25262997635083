import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const MessageBlock = ({ message }) => <div className="flix-notification flix-notification--error">{message}</div>;

export class InitializationError extends React.PureComponent {
  static propTypes = {
    initialized: PropTypes.shape({
      initialized: PropTypes.bool.isRequired,
      error: PropTypes.node,
    }).isRequired,
  };

  render() {
    const {
      initialized: { initialized, error },
    } = this.props;

    if (initialized || !error) {
      return null;
    }

    return <MessageBlock message={`Unexpected initialization error: ${error}`} />;
  }
}

const mapStateToProps = (state) => ({
  initialized: state.initialized,
});

export default connect(mapStateToProps)(InitializationError);
