import { PriceUpdateTypes } from '../constants/PriceUpdateTypes';
import { splitKey } from '../utils/priceKey';
import { getHandler } from '../utils/reducerHelper';

const lineOverrides = (key) => (state, action) => {
  const { active, class: lClass } = state.lineClass;
  const lineClass = { active, class: lClass !== '' ? lClass : state.class };

  return {
    ...state,
    lineClass: {
      oldActive: lineClass.active,
      oldClass: lClass,
      ...lineClass,
      [key]: action.value,
    },
  };
};

const handlers = {
  [PriceUpdateTypes.SET_VALUE]: (state, action) => {
    const price = state.prices[action.currency] || { value: null };

    return {
      ...state,
      prices: {
        ...state.prices,
        [action.currency]: {
          oldValue: price.value,
          ...price,
          value: action.value,
        },
      },
    };
  },
  [PriceUpdateTypes.SET_ACTIVE]: (state, action) => ({
    oldActive: state.active,
    ...state,
    active: action.value,
  }),
  [PriceUpdateTypes.SET_CC]: (state, action) => ({
    oldClass: state.class,
    ...state,
    class: action.value,
  }),
  [PriceUpdateTypes.SET_LC]: lineOverrides('class'),
  [PriceUpdateTypes.SET_LINE_DEACTIVE]: lineOverrides('active'),
  [PriceUpdateTypes.DELETE]: (state) => ({
    ...state,
    isDeleted: true,
  }),
  [PriceUpdateTypes.UNDELETE]: (state) => {
    const { isDeleted, ...newState } = state;

    return newState;
  },
};

export default function price(state, action) {
  if (!state) {
    const keyParts = splitKey(action.key);

    state = {
      ...keyParts,
      active: false,
      class: keyParts.type === 'normal' && keyParts.subType === 1 ? 1 : '',
      prices: {},
      lineClass: {
        class: '',
        active: true,
      },
    };
  }

  return getHandler(handlers, action.updateType)(state, action);
}
