import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  HIDE_FORBIDDEN_RELATIONS,
  HideForbiddenRelationsAction,
  LOAD_FORBIDDEN_RELATIONS,
  LoadForbiddenRelationsAction,
  LoadForbiddenRelationsFailAction,
  LoadForbiddenRelationsSuccessAction,
  loadLineDirectionRules as loadLineDirectionRulesAction,
  loadLineDirectionRulesFailed,
  loadLineDirectionRulesSucceeded,
} from '../actions/forbiddenRelations';
import { areForbiddenRelationsLoaded } from '../selectors/forbiddenRelationsSelector';
import { fetchLineDirectionRules } from '../utils/api';
import { errorReport } from '../utils/datadog';
import { DirectionRulesPayload } from '../utils/types';

export function* loadLineDirectionRules(action: LoadForbiddenRelationsAction) {
  const { lineId } = action;

  try {
    const lineDirectionRules: DirectionRulesPayload = yield call(fetchLineDirectionRules, lineId);

    yield put<LoadForbiddenRelationsSuccessAction>(loadLineDirectionRulesSucceeded(lineId, lineDirectionRules));
  } catch (error) {
    errorReport(`Exception during loading direction rules for line ${lineId}`, { error });
    yield put<LoadForbiddenRelationsFailAction>(loadLineDirectionRulesFailed(lineId, (error as Error).message));
  }
}

export function* hideForbiddenRelations(action: HideForbiddenRelationsAction) {
  const loaded: boolean = yield select(areForbiddenRelationsLoaded);

  if (!loaded) {
    yield put(loadLineDirectionRulesAction(action.lineId));
  }
}

export default function* loadForbiddenRelationsSaga() {
  yield takeLatest<HideForbiddenRelationsAction>(HIDE_FORBIDDEN_RELATIONS, hideForbiddenRelations);
  yield takeLatest<LoadForbiddenRelationsAction>(LOAD_FORBIDDEN_RELATIONS, loadLineDirectionRules);
}
