import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { areForbiddenRelationsVisible, getForbiddenRelations } from '../../selectors/forbiddenRelationsSelector';
import { getFromStations, getToStations } from '../../selectors/stationSelector';
import { getVisibleFareClassesObjects } from '../../selectors/visibleFareClassesSelector';
import { fareClassShape, stopShape } from '../../utils/propTypes';
import TableCell from '../TableCell';

const NewTable = (props) => {
  const { fromStations, toStations, visibleFareClasses, forbiddenRelations, forbiddenRelationsVisible } = props;

  return (
    <table className="matrix-easy-view">
      <thead className="sticky-y">
        <tr className="header table-header">
          <th key="empty" className="table-row__title table-row__station-name">
            {fromStations[0].name}
          </th>
          {visibleFareClasses.map((fareType) => (
            <th key={fareType.key} className="title table-header__title table-header__fare-name" title={fareType.label}>
              {fareType.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {toStations
          .filter(
            (toStation) =>
              !(
                !forbiddenRelationsVisible &&
                forbiddenRelations[fromStations[0].id] &&
                forbiddenRelations[fromStations[0].id][toStation.id]
              )
          )
          .map((toStop) => (
            <tr key={`row-${toStop.id}`} className="table-row">
              <th key={`x-${toStop.id}`} title={toStop.code} className="table-row__title table-row__station-name">
                {toStop.name}
              </th>
              {visibleFareClasses.map((fareType) =>
                fromStations.map((fromStop) => (
                  <TableCell
                    key={`${fromStop.id}-${toStop.id}`}
                    fromStop={fromStop}
                    toStop={toStop}
                    fareClass={fareType}
                  />
                ))
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

NewTable.propTypes = {
  fromStations: PropTypes.arrayOf(stopShape).isRequired,
  toStations: PropTypes.arrayOf(stopShape).isRequired,
  visibleFareClasses: PropTypes.arrayOf(fareClassShape).isRequired,
  forbiddenRelationsVisible: PropTypes.bool.isRequired,
  forbiddenRelations: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fromStations: getFromStations(state),
  toStations: getToStations(state),
  visibleFareClasses: getVisibleFareClassesObjects(state),
  forbiddenRelations: getForbiddenRelations(state),
  forbiddenRelationsVisible: areForbiddenRelationsVisible(state),
});

export default connect(mapStateToProps)(NewTable);
