import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { ReactNode } from 'react';

import { createMsalInstance } from './authprovider.utils';

// MSAL configuration
const msalConfig: Configuration = {
  auth: {
    // TODO: find a way to disable for test and local development
    clientId: process.env.REACT_APP_AZURE_APPLICATION_ID!,
    authority: process.env.REACT_APP_AZURE_AUTHORITY_URL,
    redirectUri: document.location.origin,
    postLogoutRedirectUri: document.location.origin,
  },
  cache: {
    storeAuthStateInCookie: false,
    cacheLocation: 'localStorage',
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiAccessRequest: RedirectRequest = {
  scopes: process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_API?.split(',') || [],
};

export const msalInstance = createMsalInstance(msalConfig);

const AuthProviderComp: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <MsalProvider instance={msalInstance}>{children}</MsalProvider>
);

export const AuthProvider = React.memo(AuthProviderComp);
export default AuthProvider;
