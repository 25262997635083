import { Hint, Icon, IconAttentionSolid } from '@flixbus/honeycomb-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  deletePrice,
  setCCValue,
  setLCValue,
  setPriceActivate,
  setPriceLineDeactivate,
  setPriceValue,
  unDeletePrice,
} from '../../actions/priceActions';
import { FareConflictTypes, FareTypes } from '../../constants/FareTypes';
import { getRelationConflicts } from '../../selectors/lcConflict.selector';
import {
  createGetPriceSelector,
  getDistanceFromProps,
  getIsNewPrice,
  isPriceValidSelector,
} from '../../selectors/priceSelectors';
import priceKey from '../../utils/priceKey';
import { fareClassShape, priceShape } from '../../utils/propTypes';
import Activation from '../Activation';
import CommonClass from '../CommonClass';
import LineClass from '../LineClass';
import LineDeactivation from '../LineDeactivation';
import Price from '../Price';
import Yielding from '../Yielding';

import DeleteButton from './DeleteButton';

import styles from './TableCell.module.scss';

class TableCell extends React.PureComponent {
  static propTypes = {
    price: priceShape,
    distance: PropTypes.number.isRequired,
    visibleCurrencies: PropTypes.array.isRequired,
    isNewPrice: PropTypes.bool,
    isEditing: PropTypes.bool.isRequired,
    setPriceValue: PropTypes.func.isRequired,
    setPriceActivate: PropTypes.func.isRequired,
    deletePrice: PropTypes.func.isRequired,
    unDeletePrice: PropTypes.func.isRequired,
    setCCValue: PropTypes.func.isRequired,
    setLCValue: PropTypes.func.isRequired,
    setPriceLineDeactivate: PropTypes.func.isRequired,
    fromStop: PropTypes.object.isRequired,
    toStop: PropTypes.object.isRequired,
    fareClass: fareClassShape.isRequired,
    lcConflicts: PropTypes.array,
  };

  onPriceValueChange = (currency, value) => {
    this.props.setPriceValue(priceKey(this.props.price), currency, value);
  };

  onCCValueChange = (value) => {
    this.props.setCCValue(priceKey(this.props.price), value);
  };

  onLCValueChange = (value) => {
    this.props.setLCValue(priceKey(this.props.price), value);
  };

  onActiveChange = (value) => {
    this.props.setPriceActivate(priceKey(this.props.price), value);
  };

  onDeactiveChange = (value) => {
    this.props.setPriceLineDeactivate(priceKey(this.props.price), value);
  };

  onDelete = () => {
    this.props.deletePrice(priceKey(this.props.price));
  };

  onUndelete = () => {
    this.props.unDeletePrice(priceKey(this.props.price));
  };

  render() {
    const { fareClass, price, distance, visibleCurrencies, isNewPrice, isEditing, isValidPrice, lcConflicts } =
      this.props;

    const conflict = lcConflicts.find((c) => c.conflictingPriceId === price.id || c.priceId === price.id);
    const conflictType =
      conflict && conflict.priceId === price.id ? FareConflictTypes.TARGET : FareConflictTypes.SOURCE;

    if (price.fromId === price.toId) {
      return <td className={classNames(styles.item, styles.empty)}>X</td>;
    }

    const lineClass = price.lineClass;
    const isInactive = Boolean(!price.active || isNewPrice || price.isDeleted);

    if (isInactive && !isEditing) {
      return <td className={styles.item} />;
    }

    const tdClass = classNames(styles.item, {
      [styles.error]: !isValidPrice,
    });

    const isDisabledForLCConflict = conflict && conflictType === FareConflictTypes.TARGET;
    const innerClass = classNames(styles.inner, {
      [styles.inactive]: isInactive || !lineClass.active || isDisabledForLCConflict,
      [styles.innerEditing]: isEditing,
    });

    const hint = conflict
      ? conflictType === FareConflictTypes.TARGET
        ? `Disabled due to overlapping Fare Class ${conflict.conflictingFareClass} with LC set`
        : `Overlapping with Fare Class ${conflict.priceFareClass}`
      : '';

    return (
      <td className={tdClass} id={price.id}>
        <div className={innerClass}>
          <div className={styles.clr}>
            <div className={styles.prices}>
              {visibleCurrencies.map((currency) => {
                const currencyPrice = price.prices[currency] || { value: '' };

                return (
                  <Price
                    key={currency}
                    currency={currency}
                    value={currencyPrice.value}
                    unsaved={'oldValue' in currencyPrice}
                    isEditing={isEditing}
                    onChange={this.onPriceValueChange}
                  />
                );
              })}
            </div>
            <Yielding distance={distance} prices={price.prices} />
            <div className={styles.classes}>
              <CommonClass
                value={price.class}
                onChange={this.onCCValueChange}
                isUnsaved={'oldClass' in price}
                isEditing={isEditing}
              />
              <LineClass
                value={lineClass.class}
                onChange={this.onLCValueChange}
                isUnsaved={'oldClass' in lineClass}
                isEditing={isEditing}
              />
            </div>
            {isEditing && <DeleteButton price={price} onDelete={this.onDelete} onUndelete={this.onUndelete} />}
          </div>
          <Activation isEditing={isEditing} active={price.active} onChange={this.onActiveChange} />
          {fareClass.key !== FareTypes.A && (
            <LineDeactivation deactivated={!lineClass.active} isEditing={isEditing} onChange={this.onDeactiveChange} />
          )}
        </div>
        {conflict && (
          <div className={styles.conflictIconContainer}>
            <Hint hint={hint} position="left">
              <Icon InlineIcon={IconAttentionSolid} extraClasses={styles.conflictIcon} size="xs" title="LC Conflict" />
            </Hint>
          </div>
        )}
      </td>
    );
  }
}

const mapStateToProps = () => {
  const getPriceSelector = createGetPriceSelector();
  const getIsNewPriceSelector = getIsNewPrice();
  const getIsPriceValidSelector = isPriceValidSelector();
  const getLcConflicts = getRelationConflicts();

  return (state, ownProps) => ({
    visibleCurrencies: state.visibleCurrencies,
    isEditing: state.isEditing,
    price: getPriceSelector(state, ownProps),
    isNewPrice: getIsNewPriceSelector(state, ownProps),
    distance: getDistanceFromProps(state, ownProps),
    isValidPrice: getIsPriceValidSelector(state, ownProps),
    lcConflicts: getLcConflicts(state, ownProps),
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPriceValue,
      setPriceActivate,
      deletePrice,
      unDeletePrice,
      setCCValue,
      setLCValue,
      setPriceLineDeactivate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableCell);
