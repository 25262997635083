import { FareTypeObjects, FareTypes, PriceTypes } from '../constants/FareTypes';

import { Price } from './types';

export const isNormalPrice = (price: Price) => price.type === PriceTypes.NORMAL;

export const isPromotionalPrice = (price: Price) =>
  price.type === PriceTypes.NORMAL && price.subType === FareTypeObjects[FareTypes.PROMOTION].subType;

export const isAPrice = (price: Price) =>
  price.type === FareTypeObjects[FareTypes.A].type && price.subType === FareTypeObjects[FareTypes.A].subType;

export const isChildrenPrice = (price: Price) =>
  price.type === FareTypeObjects[FareTypes.CHILDREN].type &&
  price.subType === FareTypeObjects[FareTypes.CHILDREN].subType;

export const isValidCC = (price: Price) =>
  (isAPrice(price) ? price.class === 1 : true) && (isChildrenPrice(price) ? price.class === 1 : true);

export const isActivePrice = (price: Price) => price && price.active && !!price.class && price.type !== 'children';

export const hasLC = (price: Price) => price.lineClass && price.lineClass.active && !!price.lineClass.class;

export const hasInactiveLC = (price: Price) => price.lineClass && !price.lineClass.active && !!price.lineClass.class;

export const isValidLC = (price: Price) =>
  (isAPrice(price) && hasLC(price) ? price.lineClass!.class === 1 : true) &&
  (isChildrenPrice(price) && hasLC(price) ? price.lineClass!.class === 1 : true);
