import { push, RouterAction } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';

import { GoToLineAction, LINES_GO_TO } from '../actions/goTo';

export function* selectLine(action: GoToLineAction) {
  yield put<RouterAction>(push(`/line/${action.lineId}`));
}

export default function* selectLineSaga() {
  yield takeEvery<GoToLineAction>(LINES_GO_TO, selectLine);
}
