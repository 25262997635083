import { datadogRum } from '@datadog/browser-rum';

export const initDataDog = () => {
  const datadogEnv = process.env.REACT_APP_DATADOG_ENV || 'dev';

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    env: datadogEnv,
    version: process.env.REACT_APP_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: false,
    defaultPrivacyLevel: datadogEnv === 'prod' ? 'mask' : 'allow',
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    allowedTracingUrls: [/^https:\/\/.*\.flix\.tech/],
  });
};

export const errorReport = datadogRum.addError;
